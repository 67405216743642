import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../../lib/firebase';
import { collection, query, orderBy, onSnapshot, where, doc, updateDoc, getDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useLocation } from 'react-router-dom';
import { SongRequest } from '../../types';
import { formatDate, getStatusLabel, getStatusStyle } from '../../utils/helpers';
import CountdownTimer from '../CountdownTimer';
import { Download, CreditCard, ChevronDown, ChevronUp, Upload, Image, Music, MessageSquare } from 'lucide-react';
import ReviewModal from '../ReviewModal';

const PAYMENT_LINKS = {
  normal: 'https://buy.stripe.com/9AQ4k4fgk7YR4zm6oo',
  express: 'https://buy.stripe.com/bIYeYI8RWgvn6HudQR'
};

export default function MyOrders() {
  const [orders, setOrders] = useState<SongRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [expandedOrderId, setExpandedOrderId] = useState<string | null>(null);
  const [uploadingCover, setUploadingCover] = useState<string | null>(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<SongRequest | null>(null);
  const [reviewedOrders, setReviewedOrders] = useState<Set<string>>(new Set());
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const checkoutSessionId = searchParams.get('order_id');
    const paymentStatus = searchParams.get('payment_status');

    const updatePaymentStatus = async () => {
      if (checkoutSessionId && paymentStatus === 'success') {
        try {
          const ordersRef = collection(db, 'songRequests');
          const q = query(ordersRef, where('orderId', '==', checkoutSessionId));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderData = orderDoc.data();

            if (!orderData.paidStatus) {
              await updateDoc(doc(db, 'songRequests', orderDoc.id), {
                paidStatus: true,
                paidAt: new Date(),
                status: 'processing'
              });
              setStatusMessage('Плащането е успешно! Вашата поръчка е в процес на изработка.');
            }
          }
          
          navigate('/dashboard', { replace: true });
        } catch (err) {
          console.error('Error updating payment status:', err);
          setError('Възникна грешка при обновяването на статуса на плащането.');
        }
      }
    };

    if (checkoutSessionId && paymentStatus) {
      updatePaymentStatus();
    }
  }, [location, navigate]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/');
        return;
      }

      const q = query(
        collection(db, 'songRequests'),
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, 
        (snapshot) => {
          const newOrders = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
            paidAt: doc.data().paidAt?.toDate(),
            completedAt: doc.data().completedAt?.toDate(),
          })) as SongRequest[];
          
          setOrders(newOrders);
          setLoading(false);
        },
        (err) => {
          console.error('Error fetching orders:', err);
          setError('Възникна грешка при зареждането на поръчките.');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    });

    return () => unsubscribeAuth();
  }, [navigate]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsQuery = query(
          collection(db, 'testimonials'),
          where('isActive', 'in', [true, false])
        );
        
        const reviewsSnapshot = await getDocs(reviewsQuery);
        const reviewedOrderIds = new Set(
          reviewsSnapshot.docs.map(doc => doc.data().orderId)
        );
        
        setReviewedOrders(reviewedOrderIds);
      } catch (err) {
        console.error('Error fetching reviews:', err);
      }
    };

    fetchReviews();
  }, []);

  const handlePayment = (order: SongRequest) => {
    const baseUrl = window.location.origin;
    const successUrl = `${baseUrl}/dashboard?order_id=${order.orderId}&payment_status=success`;
    const cancelUrl = `${baseUrl}/dashboard?order_id=${order.orderId}&payment_status=cancel`;

    const paymentUrl = `${PAYMENT_LINKS[order.orderType]}?client_reference_id=${order.orderId}&success_url=${encodeURIComponent(successUrl)}&cancel_url=${encodeURIComponent(cancelUrl)}`;
    window.location.href = paymentUrl;
  };

  const handleCoverUpload = async (orderId: string, file: File) => {
    try {
      if (!file.type.startsWith('image/')) {
        throw new Error('Моля, изберете валиден формат за изображение (JPG, PNG, etc.)');
      }

      if (file.size > 2 * 1024 * 1024) {
        throw new Error('Изображението трябва да е по-малко от 2MB');
      }

      setUploadingCover(orderId);
      setError(null);

      const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9._-]/g, '_')}`;
      const storageRef = ref(storage, `covers/${orderId}/${fileName}`);

      await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(storageRef);

      const orderRef = doc(db, 'songRequests', orderId);
      await updateDoc(orderRef, {
        coverImage: downloadURL,
        updatedAt: new Date()
      });

      setStatusMessage('Обложката беше качена успешно!');
    } catch (err) {
      console.error('Error uploading cover:', err);
      setError(err instanceof Error ? err.message : 'Грешка при качването на обложката');
    } finally {
      setUploadingCover(null);
    }
  };

  const toggleOrderExpansion = (orderId: string) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const getSongShareUrl = (orderId: string) => {
    return `${window.location.origin}/song/${orderId}`;
  };

  const handleReviewClick = (order: SongRequest) => {
    setSelectedOrder(order);
    setShowReviewModal(true);
  };

  const handleReviewSubmitted = (orderId: string) => {
    setReviewedOrders(prev => new Set([...prev, orderId]));
    setShowReviewModal(false);
    setSelectedOrder(null);
  };

  const OrderHeader = ({ order }: { order: SongRequest }) => (
    <div className="flex flex-col sm:flex-row justify-between items-start gap-4 p-6">
      <div className="flex-1 min-w-0">
        <div className="flex flex-wrap items-center gap-2 mb-2">
          {order.coverImage && (
            <img 
              src={order.coverImage} 
              alt="Cover" 
              className="w-12 h-12 rounded-lg object-cover"
            />
          )}
          <h3 className="text-xl font-semibold truncate">{order.recipientName}</h3>
          <span className={`px-2 py-1 rounded-full text-xs whitespace-nowrap ${getStatusStyle(order)}`}>
            {getStatusLabel(order)}
          </span>
          <span className={`text-xs px-2 py-1 rounded-full whitespace-nowrap ${
            order.orderType === 'express' 
              ? 'bg-pink-500/20 text-pink-400'
              : 'bg-violet-500/20 text-violet-400'
          }`}>
            {order.orderType === 'express' ? 'Експресна (24ч)' : 'Нормална (72ч)'}
          </span>
          {order.paidStatus && !order.mp3File && (
            <CountdownTimer 
              startDate={order.createdAt} 
              orderType={order.orderType}
              paidAt={order.paidAt}
            />
          )}
        </div>
        <p className="text-sm text-gray-400 mb-1 break-words">
          Номер на поръчка: {order.orderId}
        </p>
        <p className="text-sm text-gray-400 break-words">
          От: {order.senderName}
        </p>
      </div>

      <div className="flex flex-wrap items-center gap-4">
        {!order.paidStatus && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePayment(order);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg text-white transition-colors"
          >
            <CreditCard className="w-4 h-4" />
            Плати сега
          </button>
        )}
        
        {order.mp3File && (
          <>
            <div className="relative" onClick={(e) => e.stopPropagation()}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) handleCoverUpload(order.id, file);
                }}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                disabled={uploadingCover === order.id}
              />
              <button
                className={`flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg text-white ${
                  uploadingCover === order.id ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {uploadingCover === order.id ? (
                  <>
                    <Upload className="w-4 h-4 animate-spin" />
                    Качване...
                  </>
                ) : (
                  <>
                    <Image className="w-4 h-4" />
                    {order.coverImage ? 'Промени обложка' : 'Качи обложка'}
                  </>
                )}
              </button>
            </div>

            <a 
              href={`/song/${order.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg text-white"
              onClick={(e) => e.stopPropagation()}
            >
              <Music className="w-4 h-4" />
              Чуй песента
            </a>

            {!reviewedOrders.has(order.id) && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleReviewClick(order);
                }}
                className="flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg text-white"
              >
                <MessageSquare className="w-4 h-4" />
                Остави отзив
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );

  const OrderDetails = ({ order }: { order: SongRequest }) => (
    <div className="px-4 sm:px-6 pb-6">
      <div className="space-y-4 text-sm">
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Повод:</span>{' '}
              <span className="text-gray-400">{order.occasion}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">От:</span>{' '}
              <span className="text-gray-400">{order.senderName}</span>
            </p>
          </div>
        </div>

        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Жанр:</span>{' '}
              <span className="text-gray-400">{order.genre}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Език:</span>{' '}
              <span className="text-gray-400">{order.language}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Настроение:</span>{' '}
              <span className="text-gray-400">{order.mood}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Глас:</span>{' '}
              <span className="text-gray-400">{order.voice}</span>
            </p>
          </div>
        </div>

        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Имейл:</span>{' '}
              <span className="text-gray-400">{order.email}</span>
            </p>
            {order.phone && (
              <p className="break-words">
                <span className="font-medium text-gray-300">Телефон:</span>{' '}
                <span className="text-gray-400">{order.phone}</span>
              </p>
            )}
          </div>
        </div>

        <div className="p-3 bg-[#1f222a] rounded-lg space-y-3">
          <div className="space-y-2">
            <p className="font-medium text-gray-300">Описание:</p>
            <p className="text-gray-400 whitespace-pre-wrap break-words">{order.description}</p>
          </div>

          {order.specificPhrase && (
            <div className="space-y-2 pt-2 border-t border-gray-700/50">
              <p className="font-medium text-gray-300">Специална фраза:</p>
              <p className="text-gray-400 break-words">{order.specificPhrase}</p>
            </div>
          )}

          {order.additionalInfo && (
            <div className="space-y-2 pt-2 border-t border-gray-700/50">
              <p className="font-medium text-gray-300">История:</p>
              <p className="text-gray-400 whitespace-pre-wrap break-words">{order.additionalInfo}</p>
            </div>
          )}
        </div>

        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <p className="break-words">
            <span className="font-medium text-gray-300">Създадена на:</span>{' '}
            <span className="text-gray-400">{formatDate(order.createdAt)}</span>
          </p>
          {order.paidAt && (
            <p className="break-words">
              <span className="font-medium text-gray-300">Платена на:</span>{' '}
              <span className="text-gray-400">{formatDate(order.paidAt)}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="bg-[#1a1d25] rounded-xl p-6">
        <h2 className="text-xl font-semibold mb-6">История на Поръчките</h2>
        
        {statusMessage && (
          <div className="mb-6 p-4 bg-green-500/20 text-green-400 rounded-lg">
            {statusMessage}
          </div>
        )}
        
        {error && (
          <div className="mb-6 p-4 bg-red-500/20 text-red-400 rounded-lg">
            {error}
          </div>
        )}
        
        {loading ? (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500 mx-auto"></div>
            <p className="mt-4 text-gray-400">Зареждане на поръчките...</p>
          </div>
        ) : orders.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-400">Все още нямате поръчки.</p>
            <button
              onClick={() => navigate('/dashboard/new-order')}
              className="mt-4 text-violet-400 hover:text-violet-300 transition-colors"
            >
              Направете първата си поръчка
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {orders.map((order) => (
              <div
                key={order.id}
                className="bg-[#2a2d35] rounded-lg overflow-hidden"
              >
                <div 
                  className="cursor-pointer transition-colors hover:bg-[#353841]"
                  onClick={() => toggleOrderExpansion(order.id)}
                >
                  <OrderHeader order={order} />
                  <div className="px-6 pb-3 flex justify-end">
                    {expandedOrderId === order.id ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </div>
                </div>

                <div 
                  className={`transition-all duration-300 ease-in-out ${
                    expandedOrderId === order.id ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="border-t border-gray-700/50">
                    <OrderDetails order={order} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedOrder && (
        <ReviewModal 
          isOpen={showReviewModal}
          onClose={() => {
            setShowReviewModal(false);
            setSelectedOrder(null);
          }}
          onSubmit={handleReviewSubmitted}
          orderId={selectedOrder.id}
          recipientName={selectedOrder.recipientName}
        />
      )}
    </>
  );
}