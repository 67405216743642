import React from 'react';
import Footer from './Footer';

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-[#0f1117] flex flex-col">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <h1 className="text-3xl font-bold mb-8">Общи условия за ползване на сайта Melostory</h1>
          
          <div className="prose prose-invert max-w-none">
            <p className="text-gray-300 mb-8">
              Добре дошли на уебсайта https://melostory.com/ ("Сайтът"), който се управлява и поддържа от Melostory ("ние", "нас" или "нашето"). Моля, прочетете внимателно настоящите Общи условия, тъй като използването на сайта означава, че приемате и се съгласявате с тях.
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Услуги</h2>
              <div className="space-y-2 text-gray-300">
                <p>1.1. Melostory предоставя дигитален продукт под формата на индивидуално композирана песен, която се доставя като аудио файл (mp3).</p>
                <p>1.2. Песните се създават с помощта на технологии за изкуствен интелект и са персонализирани според подадената от клиента информация.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">2. Поръчка и доставка</h2>
              <div className="space-y-2 text-gray-300">
                <p>2.1. За да направите поръчка, трябва да предоставите валидно име и имейл адрес.</p>
                <p>2.2. Дигиталният продукт ще бъде доставен на посочения от вас имейл адрес в срок до [въведете срок за доставка, ако е приложимо].</p>
                <p>2.3. След успешно доставяне на продукта, поръчката се счита за финализирана и не подлежи на възстановяване.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">3. Авторски права</h2>
              <div className="space-y-2 text-gray-300">
                <p>3.1. Всички песни, създадени чрез платформата, остават интелектуална собственост на Melostory.</p>
                <p>3.2. Клиентът има право свободно да разпространява и споделя получената песен, но не може да претендира за авторство или да я използва за комерсиални цели без предварително писмено съгласие от Melostory.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">4. Лични данни</h2>
              <div className="space-y-2 text-gray-300">
                <p>4.1. Ние събираме само информация, необходима за изпълнението на поръчките: име и имейл адрес.</p>
                <p>4.2. За повече информация относно обработката на лични данни, моля, прочетете нашата Политика за поверителност.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">5. Ограничение на отговорността</h2>
              <div className="space-y-2 text-gray-300">
                <p>5.1. Melostory не носи отговорност за:</p>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Грешки или забавяния, причинени от некоректно предоставена информация от клиента.</li>
                  <li>Проблеми, свързани с достъпа до или използването на платформата, които са извън нашия контрол.</li>
                </ul>
                <p>5.2. Melostory си запазва правото да променя, спира или прекратява услуги на Сайта без предварително уведомление.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. Изменение на общите условия</h2>
              <div className="space-y-2 text-gray-300">
                <p>6.1. Melostory си запазва правото да променя настоящите Общи условия по всяко време, като публикува актуализирана версия на сайта.</p>
                <p>6.2. Продължаващото използване на сайта след промените означава, че приемате новите Общи условия.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. Контакти</h2>
              <div className="space-y-2 text-gray-300">
                <p>За въпроси или допълнителна информация, свържете се с нас на <a href="mailto:office@melostory.com" className="text-violet-400 hover:text-violet-300">office@melostory.com</a>.</p>
              </div>
            </section>

            <p className="text-gray-300 mt-8">
              Благодарим ви, че избрахте Melostory!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}