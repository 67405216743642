import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Check if we're in development mode
const isDevelopment = import.meta.env.DEV;

// Function to validate Firebase config
function validateFirebaseConfig() {
  const requiredEnvVars = [
    'VITE_FIREBASE_API_KEY',
    'VITE_FIREBASE_AUTH_DOMAIN',
    'VITE_FIREBASE_PROJECT_ID',
    'VITE_FIREBASE_STORAGE_BUCKET',
    'VITE_FIREBASE_MESSAGING_SENDER_ID',
    'VITE_FIREBASE_APP_ID'
  ];

  const missingVars = requiredEnvVars.filter(varName => !import.meta.env[varName]);

  if (missingVars.length > 0) {
    // In development, show which variables are missing
    if (isDevelopment) {
      console.error('Missing Firebase configuration variables:', missingVars);
      console.error(`
Please create a .env file in the project root with the following variables:

${missingVars.map(varName => `${varName}=your_value_here`).join('\n')}

You can find these values in your Firebase Console under Project Settings > General > Your Apps > SDK setup and configuration.
      `);
    }
    
    // Throw a user-friendly error
    throw new Error(
      isDevelopment 
        ? 'Firebase configuration is incomplete. Check the console for details.'
        : 'Application configuration error. Please contact support.'
    );
  }
}

// Validate configuration
validateFirebaseConfig();

// Initialize Firebase with validated config
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };