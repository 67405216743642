import React from 'react';
import { Mail, Facebook, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="border-t border-gray-800 mt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8">
          {/* Logo and Contact */}
          <div className="text-center sm:text-left">
            <div className="flex items-center justify-center sm:justify-start mb-4">
              <img 
                src="https://i.imgur.com/UBtGmSL.png" 
                alt="MeloStory Logo" 
                className="h-12 w-auto"
              />
            </div>
            <div className="flex items-center justify-center sm:justify-start text-gray-400 hover:text-white transition-colors">
              <Mail className="h-5 w-5 mr-2" />
              <a href="mailto:contact@melostory.com">office@melostory.com</a>
            </div>
          </div>

          {/* Links */}
          <div className="text-center sm:text-right">
            <h3 className="font-semibold mb-4">Правна информация</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                  Общи условия
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                  Политика за поверителност
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-6 mb-8">
          <a 
            href="https://www.facebook.com/profile.php?id=61572413589934" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white transition-colors"
          >
            <Facebook className="h-6 w-6" />
            <span className="sr-only">Facebook</span>
          </a>
          <a 
            href="https://www.instagram.com/melostory.com_ig/?next=%2F" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white transition-colors"
          >
            <Instagram className="h-6 w-6" />
            <span className="sr-only">Instagram</span>
          </a>
          <a 
            href="https://www.tiktok.com/@melostory.com_tiktok?is_from_webapp=1&sender_device=pc" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg 
              viewBox="0 0 24 24" 
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
            </svg>
            <span className="sr-only">TikTok</span>
          </a>
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 pt-8 border-t border-gray-800">
          <p className="text-gray-400 text-center">© 2025 MeloStory. Всички права запазени.</p>
        </div>
      </div>
    </footer>
  );
}