import React, { useState, useEffect } from 'react';
import { db, storage } from '../../lib/firebase';
import { collection, addDoc, query, orderBy, onSnapshot, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Music, Trash2, Loader, Star } from 'lucide-react';
import type { SampleSong } from '../../types';

export default function SongManager() {
  const [songs, setSongs] = useState<SampleSong[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    genre: '',
    rating: 5,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [mp3File, setMp3File] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const q = query(collection(db, 'sampleSongs'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const songData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      })) as SampleSong[];
      
      setSongs(songData);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setUploading(true);

    try {
      if (!imageFile || !mp3File) {
        throw new Error('Моля, изберете изображение и MP3 файл');
      }

      // Upload image
      const imageRef = ref(storage, `sample-songs/images/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      // Upload MP3
      const mp3Ref = ref(storage, `sample-songs/mp3/${mp3File.name}`);
      await uploadBytes(mp3Ref, mp3File);
      const mp3Url = await getDownloadURL(mp3Ref);

      // Add to Firestore
      await addDoc(collection(db, 'sampleSongs'), {
        ...formData,
        imageUrl,
        mp3Url,
        createdAt: new Date(),
      });

      // Reset form
      setFormData({ name: '', genre: '', rating: 5 });
      setImageFile(null);
      setMp3File(null);
      
      // Reset file inputs
      const imageInput = document.getElementById('imageFile') as HTMLInputElement;
      const mp3Input = document.getElementById('mp3File') as HTMLInputElement;
      if (imageInput) imageInput.value = '';
      if (mp3Input) mp3Input.value = '';

    } catch (err) {
      console.error('Error adding song:', err);
      setError(err instanceof Error ? err.message : 'Грешка при добавяне на песента');
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (songId: string) => {
    if (!confirm('Сигурни ли сте, че искате да изтриете тази песен?')) return;
    
    try {
      await deleteDoc(doc(db, 'sampleSongs', songId));
    } catch (err) {
      console.error('Error deleting song:', err);
      setError('Грешка при изтриване на песента');
    }
  };

  const handleRatingChange = async (songId: string, newRating: number) => {
    try {
      const songRef = doc(db, 'sampleSongs', songId);
      await updateDoc(songRef, { rating: newRating });
    } catch (err) {
      console.error('Error updating rating:', err);
      setError('Грешка при обновяване на рейтинга');
    }
  };

  return (
    <div className="space-y-8">
      <div className="bg-[#1a1d25] p-6 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Добави нова примерна песен</h3>
        
        {error && (
          <div className="mb-4 p-3 bg-red-500/20 text-red-400 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Име</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Жанр</label>
            <input
              type="text"
              value={formData.genre}
              onChange={(e) => setFormData(prev => ({ ...prev, genre: e.target.value }))}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Рейтинг</label>
            <select
              value={formData.rating}
              onChange={(e) => setFormData(prev => ({ ...prev, rating: parseInt(e.target.value) }))}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
            >
              {[1, 2, 3, 4, 5].map(num => (
                <option key={num} value={num}>{num} звезди</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Изображение</label>
            <input
              id="imageFile"
              type="file"
              accept="image/*"
              onChange={(e) => setImageFile(e.target.files?.[0] || null)}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">MP3 Файл</label>
            <input
              id="mp3File"
              type="file"
              accept="audio/mp3"
              onChange={(e) => setMp3File(e.target.files?.[0] || null)}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>

          <button
            type="submit"
            disabled={uploading}
            className={`flex items-center justify-center gap-2 w-full py-3 rounded-lg font-medium transition-colors ${
              uploading 
                ? 'bg-violet-500/50 cursor-not-allowed' 
                : 'bg-violet-500 hover:bg-violet-600'
            }`}
          >
            {uploading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                Качване...
              </>
            ) : (
              <>
                <Music className="w-5 h-5" />
                Добави песен
              </>
            )}
          </button>
        </form>
      </div>

      <div className="bg-[#1a1d25] p-6 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Примерни песни</h3>
        
        {loading ? (
          <div className="text-center py-8">
            <Loader className="w-8 h-8 animate-spin mx-auto" />
          </div>
        ) : songs.length === 0 ? (
          <p className="text-center text-gray-400 py-8">Все още няма добавени примерни песни.</p>
        ) : (
          <div className="space-y-4">
            {songs.map((song) => (
              <div key={song.id} className="flex items-center justify-between p-4 bg-[#2a2d35] rounded-lg">
                <div className="flex items-center gap-4">
                  <img 
                    src={song.imageUrl} 
                    alt={song.name} 
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                  <div>
                    <h4 className="font-medium">{song.name}</h4>
                    <p className="text-sm text-gray-400">{song.genre}</p>
                    <div className="flex items-center gap-1 mt-1">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          onClick={() => handleRatingChange(song.id, star)}
                          className={`p-0.5 transition-colors ${
                            star <= (song.rating || 0)
                              ? 'text-yellow-400'
                              : 'text-gray-500'
                          }`}
                        >
                          <Star className="w-4 h-4 fill-current" />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleDelete(song.id)}
                  className="p-2 text-red-400 hover:text-red-300 transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}