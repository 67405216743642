import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const questions = [
  {
    question: 'Как мога да направя поръчка за персонализирана песен?',
    answer: 'Можете да направите поръчка, като попълните нашия въпросник за персонализация и заплатите услугата. След това ние ще обработим вашата поръчка.',
  },
  {
    question: 'Колко време отнема изработката на песента?',
    answer: 'Песента ще бъде готова и доставена в рамките на 3 работни дни във висококачествен MP3 аудиоформат. Можете да използвате опцията, "експресна поръчка", която ще приоритизира създаването на вашата песен (24 часа) и изисква допълнително заплащане.',
  },
  {
    question: 'Възможно ли е да поръчам физически носител за песента?',
    answer: 'Да, ако желаете физически носител (диск или USB), това може да бъде уговорено допълнително и изисква допълнително заплащане.',
  },
  {
    question: 'Има ли ограничения за съдържанието на поръчаните песни?',
    answer: 'Да, не приемаме текстове или истории, съдържащи обиден, дискриминационен или неприемлив език. При подобно съдържание поръчката ще бъде отказана, а сумата ще бъде възстановена.',
  },
  {
    question: 'Как ще получа готовата си песен?',
    answer: 'Ще получите висококачествен MP3 файл на песента с възможност за сваляне в профила създаден в нашия сайт и по имейл. Така ще можете да споделите песента, когато и с когото пожелаете.',
  },
  {
    question: 'Предлагате ли услуги за бизнеси или събития?',
    answer: 'Абсолютно! Ние сме специализирани в създаването на персонализирани песни за бизнеси, корпоративни събития и специални поводи. При по-големи поръчки (над 10 песни) се свържете с нас по имейл на <a href="mailto:office@melostory.com" className="text-violet-400 hover:text-violet-300">office@melostory.com</a>',
  },
  {
    question: 'Какво се случва, ако не можете да изпълните поръчката?',
    answer: 'При невъзможност за изготвяне на песента, ще се свържем с вас за уточнение, а платената сума ще бъде възстановена.',
  },
  {
    question: 'Как да се свържа с вас, ако имам допълнителни въпроси?',
    answer: 'Можете да се свържете с нас по имейл на <a href="mailto:office@melostory.com" className="text-violet-400 hover:text-violet-300">office@melostory.com</a>. Ще се постараем да ви върнем отговор възможно най-скоро след като ни пишете',
  },
  {
    question: 'Какво се случва, ако предоставената информация е неточна или непълна?',
    answer: 'Клиентът носи отговорност за точността на информацията във въпросника. Неточни данни могат да повлияят на качеството на крайния резултат.',
  },
  {
    question: 'Каква е цената на една песен?',
    answer: 'Цената за персонално композирана песен зависи от времето за изпълнение. Нормалната поръчка е до 72 часа и струва 93.00 лв., а ако искате експресна поръчка до 24 часа ще ви струва 153.00 лв. ',
  },
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Split questions into two columns for desktop
  const midPoint = Math.ceil(questions.length / 2);
  const leftColumnQuestions = questions.slice(0, midPoint);
  const rightColumnQuestions = questions.slice(midPoint);

  const QuestionItem = ({ item, index }: { item: typeof questions[0], index: number }) => (
    <div className="bg-[#1a1d25] rounded-lg overflow-hidden">
      <button
        onClick={() => toggleQuestion(index)}
        className="w-full p-6 flex items-center justify-between text-left hover:bg-[#1e2129] transition-colors"
      >
        <h3 className="text-lg font-semibold">{item.question}</h3>
        <ChevronDown 
          className={`w-5 h-5 text-violet-500 transition-transform duration-200 flex-shrink-0 ${
            openIndex === index ? 'rotate-180' : ''
          }`}
        />
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ease-in-out ${
          openIndex === index ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <p className="p-6 pt-0 text-gray-400" dangerouslySetInnerHTML={{ __html: item.answer }} />
      </div>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <h2 className="text-3xl font-bold text-center mb-16">
        Често Задавани Въпроси
      </h2>
      
      {/* Mobile: Single Column */}
      <div className="lg:hidden space-y-4">
        {questions.map((item, index) => (
          <QuestionItem key={index} item={item} index={index} />
        ))}
      </div>

      {/* Desktop: Two Columns */}
      <div className="hidden lg:grid lg:grid-cols-2 lg:gap-6">
        <div className="space-y-4">
          {leftColumnQuestions.map((item, index) => (
            <QuestionItem key={index} item={item} index={index} />
          ))}
        </div>
        <div className="space-y-4">
          {rightColumnQuestions.map((item, index) => (
            <QuestionItem key={index + midPoint} item={item} index={index + midPoint} />
          ))}
        </div>
      </div>
    </div>
  );
}