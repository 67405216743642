import React from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { Music, History, Settings } from 'lucide-react';
import { auth } from '../lib/firebase';

export default function DashboardLayout() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div className="flex flex-col sm:flex-row gap-6 mb-12">
        <NavLink
          to="/dashboard/orders"
          className={({ isActive }) => `
            flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors
            ${isActive 
              ? 'bg-violet-500 text-white'
              : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
            }
          `}
        >
          <History className="w-5 h-5" />
          Моите Поръчки
        </NavLink>
        
        <NavLink
          to="/dashboard/new-order"
          className={({ isActive }) => `
            flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors
            ${isActive 
              ? 'bg-violet-500 text-white'
              : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
            }
          `}
        >
          <Music className="w-5 h-5" />
          Поръчай Песен
        </NavLink>
        
        <NavLink
          to="/dashboard/settings"
          className={({ isActive }) => `
            flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors
            ${isActive 
              ? 'bg-violet-500 text-white'
              : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
            }
          `}
        >
          <Settings className="w-5 h-5" />
          Настройки
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
}