import React, { useState } from 'react';
import { X } from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { trackPurchase } from '../lib/facebook-pixel';

interface OrderCompletionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (orderType: 'normal' | 'express') => void;
  formData: any;
}

// Payment links with proper redirect URLs
const PAYMENT_LINKS = {
  normal: 'https://buy.stripe.com/9AQ4k4fgk7YR4zm6oo',
  express: 'https://buy.stripe.com/bIYeYI8RWgvn6HudQR'
};

export default function OrderCompletionModal({ 
  isOpen, 
  onClose, 
  onComplete,
  formData 
}: OrderCompletionModalProps) {
  const navigate = useNavigate();
  const [orderType, setOrderType] = useState<'normal' | 'express'>('normal');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });

  const isLoggedIn = auth.currentUser !== null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const generateOrderId = () => {
    const timestamp = Date.now().toString(36);
    const randomStr = Math.random().toString(36).substring(2, 8);
    return `ORD-${timestamp}-${randomStr}`.toUpperCase();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!isLoggedIn) {
        if (userData.password !== userData.confirmPassword) {
          throw new Error('Паролите не съвпадат');
        }

        const userCredential = await createUserWithEmailAndPassword(
          auth, 
          userData.email, 
          userData.password
        );
        
        await updateProfile(userCredential.user, {
          displayName: userData.name
        });

        await setDoc(doc(db, 'users', userCredential.user.uid), {
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          createdAt: new Date()
        });
      }

      const orderId = generateOrderId();
      
      // Create the order document first
      const orderData = {
        ...formData,
        orderId,
        createdAt: new Date(),
        status: 'pending',
        orderType,
        userId: auth.currentUser?.uid,
        email: isLoggedIn ? auth.currentUser?.email : userData.email,
        phone: userData.phone || '',
        paidStatus: false,
        mp3File: null,
        paidAt: null
      };

      // Save order to Firestore with orderId as the document ID
      await setDoc(doc(db, 'songRequests', orderId), orderData);

      trackPurchase(orderType);
      await onComplete(orderType);
      onClose();

      // Build the success and cancel URLs
      const baseUrl = window.location.origin;
      const successUrl = `${baseUrl}/dashboard?order_id=${orderId}&payment_status=success`;
      const cancelUrl = `${baseUrl}/dashboard?order_id=${orderId}&payment_status=cancel`;

      // Add metadata to the URL
      const metadata = encodeURIComponent(JSON.stringify({ orderId }));

      // Construct the final payment URL with all necessary parameters
      const paymentUrl = `${PAYMENT_LINKS[orderType]}?client_reference_id=${orderId}&metadata=${metadata}&success_url=${encodeURIComponent(successUrl)}&cancel_url=${encodeURIComponent(cancelUrl)}`;
      window.location.href = paymentUrl;

    } catch (err: any) {
      let errorMessage = 'Възникна грешка. Моля, опитайте отново.';
      if (err.code === 'auth/email-already-in-use') {
        errorMessage = 'Потребител с този имейл вече е регистриран.';
      } else if (err.code === 'auth/invalid-email') {
        errorMessage = 'Невалиден имейл адрес.';
      } else if (err.code === 'auth/weak-password') {
        errorMessage = 'Паролата трябва да е поне 6 символа.';
      } else if (err.message) {
        errorMessage = err.message;
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1a1d25] p-8 rounded-xl w-full max-w-md relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">
          Данни за поръчката
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/20 text-red-400 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">
              Избери тип на поръчката
            </label>
            <div className="grid grid-cols-2 gap-3">
              <button
                type="button"
                onClick={() => setOrderType('normal')}
                className={`p-3 rounded-lg text-center transition-colors ${
                  orderType === 'normal'
                    ? 'bg-violet-500 text-white'
                    : 'bg-[#2a2d35] text-gray-300 hover:bg-[#353841]'
                }`}
              >
                Нормална
                <span className="block text-sm opacity-75">93.00 лв.</span>
                <span className="block text-xs opacity-75">до 72 часа</span>
              </button>
              <button
                type="button"
                onClick={() => setOrderType('express')}
                className={`p-3 rounded-lg text-center transition-colors ${
                  orderType === 'express'
                    ? 'bg-violet-500 text-white'
                    : 'bg-[#2a2d35] text-gray-300 hover:bg-[#353841]'
                }`}
              >
                Експресна
                <span className="block text-sm opacity-75">153.00 лв.</span>
                <span className="block text-xs opacity-75">до 24 часа</span>
              </button>
            </div>
          </div>

          {!isLoggedIn && (
            <div className="space-y-4">
              <div className="text-left">
                <label className="block text-sm font-medium mb-1">Име</label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium mb-1">Имейл</label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium mb-1">Тел. номер</label>
                <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium mb-1">Създай парола</label>
                <input
                  type="password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium mb-1">Повтори парола</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 rounded-lg font-medium transition-colors ${
              loading 
                ? 'bg-violet-500/50 cursor-not-allowed' 
                : 'bg-violet-500 hover:bg-violet-600'
            }`}
          >
            {loading ? 'Зареждане...' : 'Продължи към плащане'}
          </button>
        </form>
      </div>
    </div>
  );
}