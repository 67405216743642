import React, { useState, useEffect } from 'react';
import { db, storage, auth } from '../../lib/firebase';
import { collection, query, orderBy, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Search, Upload, Loader, CheckCircle, ChevronDown, ChevronUp, Download } from 'lucide-react';
import type { SongRequest } from '../../types';

export default function OrderManager() {
  const [orders, setOrders] = useState<SongRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadingOrderId, setUploadingOrderId] = useState<string | null>(null);
  const [confirmingPayment, setConfirmingPayment] = useState<string | null>(null);
  const [expandedOrderId, setExpandedOrderId] = useState<string | null>(null);

  const handleFileUpload = async (orderId: string, file: File) => {
    try {
      // Validate file type
      if (!file.type.match('audio/mpeg') || !file.name.toLowerCase().endsWith('.mp3')) {
        throw new Error('Моля, изберете валиден MP3 файл');
      }

      // Validate file size (20MB max)
      if (file.size > 20 * 1024 * 1024) {
        throw new Error('Файлът трябва да е по-малък от 20MB');
      }

      const user = auth.currentUser;
      if (!user) {
        throw new Error('Не сте оторизиран');
      }

      setUploading(true);
      setUploadingOrderId(orderId);
      setError(null);

      // Get the order first to verify it exists
      const orderRef = doc(db, 'songRequests', orderId);
      const orderSnap = await getDoc(orderRef);
      
      if (!orderSnap.exists()) {
        throw new Error('Поръчката не съществува');
      }

      // Create a reference to the file in Firebase Storage
      const fileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9._-]/g, '_')}`;
      const storageRef = ref(storage, `songs/${orderId}/${fileName}`);

      // Upload the file
      const uploadResult = await uploadBytes(storageRef, file, {
        contentType: 'audio/mpeg',
        customMetadata: {
          orderId,
          uploadedBy: user.email || 'unknown',
          originalName: file.name
        }
      });

      // Get the download URL
      const downloadURL = await getDownloadURL(uploadResult.ref);

      // Update the order in Firestore
      await updateDoc(orderRef, {
        mp3File: downloadURL,
        status: 'completed',
        completedAt: new Date(),
        updatedAt: new Date(),
        updatedBy: user.uid
      });

      setError(null);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(err instanceof Error ? err.message : 'Грешка при качването на файла');
    } finally {
      setUploading(false);
      setUploadingOrderId(null);
    }
  };

  const handleConfirmPayment = async (orderId: string) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Не сте оторизиран');
      }

      setConfirmingPayment(orderId);
      setError(null);

      const orderRef = doc(db, 'songRequests', orderId);
      await updateDoc(orderRef, {
        paidStatus: true,
        paidAt: new Date(),
        status: 'processing',
        updatedAt: new Date(),
        updatedBy: user.uid,
        paymentConfirmedBy: user.email
      });

      setError(null);
    } catch (err) {
      console.error('Error confirming payment:', err);
      setError(err instanceof Error ? err.message : 'Грешка при потвърждаване на плащането');
    } finally {
      setConfirmingPayment(null);
    }
  };

  useEffect(() => {
    const q = query(
      collection(db, 'songRequests'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const newOrders = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          paidAt: doc.data().paidAt?.toDate(),
          completedAt: doc.data().completedAt?.toDate(),
        })) as SongRequest[];
        
        setOrders(newOrders);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching orders:', err);
        setError('Грешка при зареждане на поръчките');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const formatDate = (date: Date) => {
    return date.toLocaleString('bg-BG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const toggleOrderExpansion = (orderId: string) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const filteredOrders = orders.filter(order => 
    order.recipientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.occasion.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.orderId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const OrderHeader = ({ order }: { order: SongRequest }) => (
    <div className="flex flex-col sm:flex-row justify-between items-start gap-4 p-6">
      <div className="flex-1 min-w-0">
        <div className="flex flex-wrap items-center gap-2 mb-2">
          <h3 className="text-xl font-semibold truncate">{order.recipientName}</h3>
          <span className={`px-2 py-1 rounded-full text-xs whitespace-nowrap ${
            !order.paidStatus ? 'bg-yellow-500/20 text-yellow-400' :
            order.mp3File ? 'bg-green-500/20 text-green-400' :
            'bg-blue-500/20 text-blue-400'
          }`}>
            {!order.paidStatus ? 'Очаква плащане' :
             order.mp3File ? 'Завършена' : 'В процес'}
          </span>
          <span className={`text-xs px-2 py-1 rounded-full whitespace-nowrap ${
            order.orderType === 'express' 
              ? 'bg-pink-500/20 text-pink-400'
              : 'bg-violet-500/20 text-violet-400'
          }`}>
            {order.orderType === 'express' ? 'Експресна (24ч)' : 'Нормална (72ч)'}
          </span>
        </div>
        <p className="text-sm text-gray-400 mb-1 break-words">
          Номер на поръчка: {order.orderId}
        </p>
        <p className="text-sm text-gray-400 break-words">
          От: {order.senderName}
        </p>
      </div>

      <div className="flex flex-wrap items-center gap-4">
        {!order.paidStatus && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleConfirmPayment(order.id);
            }}
            disabled={confirmingPayment === order.id}
            className={`flex items-center gap-2 px-4 py-2 bg-green-500 hover:bg-green-600 rounded-lg text-white ${
              confirmingPayment === order.id ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <CheckCircle className="w-4 h-4" />
            {confirmingPayment === order.id ? 'Потвърждаване...' : 'Платено'}
          </button>
        )}

        {order.paidStatus && !order.mp3File && (
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <input
              type="file"
              accept=".mp3,audio/mpeg"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileUpload(order.id, file);
              }}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              disabled={uploading}
            />
            <button
              className={`flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg ${
                uploading && uploadingOrderId === order.id ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <Upload className="w-4 h-4" />
              {uploading && uploadingOrderId === order.id ? 'Качване...' : 'Качи MP3'}
            </button>
          </div>
        )}
        
        {order.mp3File && (
          <a
            href={order.mp3File}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg text-white"
            onClick={(e) => e.stopPropagation()}
          >
            <Download className="w-4 h-4" />
            Изтегли песента
          </a>
        )}
      </div>
    </div>
  );

  const OrderDetails = ({ order }: { order: SongRequest }) => (
    <div className="px-4 sm:px-6 pb-6">
      <div className="space-y-4 text-sm">
        {/* Basic Info Section */}
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Повод:</span>{' '}
              <span className="text-gray-400">{order.occasion}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">От:</span>{' '}
              <span className="text-gray-400">{order.senderName}</span>
            </p>
          </div>
        </div>

        {/* Song Details Section */}
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Жанр:</span>{' '}
              <span className="text-gray-400">{order.genre}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Език:</span>{' '}
              <span className="text-gray-400">{order.language}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Настроение:</span>{' '}
              <span className="text-gray-400">{order.mood}</span>
            </p>
            <p className="break-words">
              <span className="font-medium text-gray-300">Глас:</span>{' '}
              <span className="text-gray-400">{order.voice}</span>
            </p>
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <div className="grid grid-cols-2 gap-2">
            <p className="break-words">
              <span className="font-medium text-gray-300">Имейл:</span>{' '}
              <span className="text-gray-400">{order.email}</span>
            </p>
            {order.phone && (
              <p className="break-words">
                <span className="font-medium text-gray-300">Телефон:</span>{' '}
                <span className="text-gray-400">{order.phone}</span>
              </p>
            )}
          </div>
        </div>

        {/* Description Section */}
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-3">
          <div className="space-y-2">
            <p className="font-medium text-gray-300">Описание:</p>
            <p className="text-gray-400 whitespace-pre-wrap break-words">{order.description}</p>
          </div>

          {order.specificPhrase && (
            <div className="space-y-2 pt-2 border-t border-gray-700/50">
              <p className="font-medium text-gray-300">Специална фраза:</p>
              <p className="text-gray-400 break-words">{order.specificPhrase}</p>
            </div>
          )}

          {order.additionalInfo && (
            <div className="space-y-2 pt-2 border-t border-gray-700/50">
              <p className="font-medium text-gray-300">История:</p>
              <p className="text-gray-400 whitespace-pre-wrap break-words">{order.additionalInfo}</p>
            </div>
          )}
        </div>

        {/* Timestamps Section */}
        <div className="p-3 bg-[#1f222a] rounded-lg space-y-2">
          <p className="break-words">
            <span className="font-medium text-gray-300">Създадена на:</span>{' '}
            <span className="text-gray-400">{formatDate(order.createdAt)}</span>
          </p>
          {order.paidAt && (
            <p className="break-words">
              <span className="font-medium text-gray-300">Платена на:</span>{' '}
              <span className="text-gray-400">{formatDate(order.paidAt)}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Търси поръчки..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-[#1a1d25] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-500/20 text-red-400 rounded-lg">
          {error}
        </div>
      )}

      {loading ? (
        <div className="text-center py-8">
          <Loader className="w-8 h-8 animate-spin mx-auto" />
          <p className="mt-4 text-gray-400">Зареждане на поръчките...</p>
        </div>
      ) : filteredOrders.length === 0 ? (
        <div className="text-center py-8 text-gray-400">
          {error ? error : 'Няма намерени поръчки'}
        </div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div
              key={order.id}
              className="bg-[#2a2d35] rounded-lg overflow-hidden"
            >
              <div 
                className="cursor-pointer transition-colors hover:bg-[#353841]"
                onClick={() => toggleOrderExpansion(order.id)}
              >
                <OrderHeader order={order} />
                <div className="px-6 pb-3 flex justify-end">
                  {expandedOrderId === order.id ? (
                    <ChevronUp className="w-5 h-5 text-gray-400" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  )}
                </div>
              </div>

              <div 
                className={`transition-all duration-300 ease-in-out ${
                  expandedOrderId === order.id ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <div className="border-t border-gray-700/50">
                  <OrderDetails order={order} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}