import React, { useEffect, useState } from 'react';

interface CountdownTimerProps {
  startDate: Date;
  orderType: 'normal' | 'express';
  paidAt?: Date;
}

export default function CountdownTimer({ startDate, orderType, paidAt }: CountdownTimerProps) {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      // Use paidAt time if available, otherwise fall back to startDate
      const start = paidAt ? new Date(paidAt).getTime() : new Date(startDate).getTime();
      const hoursToAdd = orderType === 'express' ? 24 : 72;
      const completion = start + (hoursToAdd * 60 * 60 * 1000);
      const difference = completion - now;

      if (difference <= 0) {
        return 'Очаква се скоро';
      }

      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      return `${hours}ч ${minutes}м`;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, [startDate, orderType, paidAt]);

  return (
    <div className="text-sm text-gray-400">
      ⌛ {timeLeft}
    </div>
  );
}