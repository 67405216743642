import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Star, Loader, Trash2, Edit, Plus, X, Check } from 'lucide-react';
import type { Testimonial } from '../../types';

export default function TestimonialManager() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    rating: 5,
    isActive: true
  });
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    const q = query(collection(db, 'testimonials'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const testimonialData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      })) as Testimonial[];
      
      setTestimonials(testimonialData);
      setLoading(false);
    }, (err) => {
      console.error('Error fetching testimonials:', err);
      setError('Грешка при зареждане на отзивите');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      if (editingId) {
        // Update existing testimonial
        await updateDoc(doc(db, 'testimonials', editingId), {
          ...formData,
          updatedAt: new Date()
        });
      } else {
        // Add new testimonial
        await addDoc(collection(db, 'testimonials'), {
          ...formData,
          createdAt: new Date()
        });
      }

      // Reset form
      setFormData({
        name: '',
        content: '',
        rating: 5,
        isActive: true
      });
      setShowAddForm(false);
      setEditingId(null);
    } catch (err) {
      console.error('Error saving testimonial:', err);
      setError('Грешка при запазване на отзива');
    }
  };

  const handleEdit = (testimonial: Testimonial) => {
    setFormData({
      name: testimonial.name,
      content: testimonial.content,
      rating: testimonial.rating,
      isActive: testimonial.isActive
    });
    setEditingId(testimonial.id);
    setShowAddForm(true);
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Сигурни ли сте, че искате да изтриете този отзив?')) return;
    
    try {
      await deleteDoc(doc(db, 'testimonials', id));
    } catch (err) {
      console.error('Error deleting testimonial:', err);
      setError('Грешка при изтриване на отзива');
    }
  };

  const handleToggleStatus = async (id: string, currentStatus: boolean) => {
    try {
      await updateDoc(doc(db, 'testimonials', id), {
        isActive: !currentStatus,
        updatedAt: new Date()
      });
    } catch (err) {
      console.error('Error updating testimonial status:', err);
      setError('Грешка при промяна на статуса');
    }
  };

  return (
    <div className="bg-[#1a1d25] p-6 rounded-lg">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">Управление на отзиви</h2>
        <button
          onClick={() => setShowAddForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-violet-500 hover:bg-violet-600 rounded-lg transition-colors"
        >
          <Plus className="w-4 h-4" />
          Добави отзив
        </button>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-500/20 text-red-400 rounded-lg">
          {error}
        </div>
      )}

      {showAddForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-[#1a1d25] p-8 rounded-xl w-full max-w-md relative">
            <button 
              onClick={() => {
                setShowAddForm(false);
                setEditingId(null);
                setFormData({
                  name: '',
                  content: '',
                  rating: 5,
                  isActive: true
                });
              }}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
            >
              <X className="w-6 h-6" />
            </button>

            <h3 className="text-xl font-semibold mb-6">
              {editingId ? 'Редактирай отзив' : 'Добави нов отзив'}
            </h3>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Име</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Съдържание</label>
                <textarea
                  value={formData.content}
                  onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500 h-32 resize-none"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Рейтинг</label>
                <div className="flex gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, rating: star }))}
                      className="text-2xl focus:outline-none"
                    >
                      <Star
                        className={`w-6 h-6 ${
                          star <= formData.rating
                            ? 'text-yellow-400 fill-yellow-400'
                            : 'text-gray-500'
                        }`}
                      />
                    </button>
                  ))}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="isActive"
                  checked={formData.isActive}
                  onChange={(e) => setFormData(prev => ({ ...prev, isActive: e.target.checked }))}
                  className="w-4 h-4 rounded border-gray-300 text-violet-500 focus:ring-violet-500"
                />
                <label htmlFor="isActive" className="text-sm font-medium">
                  Активен
                </label>
              </div>

              <button
                type="submit"
                className="w-full py-3 rounded-lg font-medium bg-violet-500 hover:bg-violet-600 transition-colors"
              >
                {editingId ? 'Запази промените' : 'Добави отзив'}
              </button>
            </form>
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center py-8">
          <Loader className="w-8 h-8 animate-spin mx-auto" />
          <p className="mt-4 text-gray-400">Зареждане на отзиви...</p>
        </div>
      ) : testimonials.length === 0 ? (
        <p className="text-center text-gray-400 py-8">
          Няма добавени отзиви
        </p>
      ) : (
        <div className="space-y-4">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-[#2a2d35] p-6 rounded-lg"
            >
              <div className="flex justify-between items-start gap-4">
                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-2">
                    <h3 className="font-medium">{testimonial.name}</h3>
                    <span
                      className={`px-2 py-0.5 rounded-full text-xs ${
                        testimonial.isActive
                          ? 'bg-green-500/20 text-green-400'
                          : 'bg-gray-500/20 text-gray-400'
                      }`}
                    >
                      {testimonial.isActive ? 'Активен' : 'Неактивен'}
                    </span>
                  </div>
                  
                  <div className="flex items-center gap-1 mb-2">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`w-4 h-4 ${
                          star <= testimonial.rating
                            ? 'text-yellow-400 fill-yellow-400'
                            : 'text-gray-500'
                        }`}
                      />
                    ))}
                  </div>
                  
                  <p className="text-gray-400">{testimonial.content}</p>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleToggleStatus(testimonial.id, testimonial.isActive)}
                    className={`p-2 rounded-lg transition-colors ${
                      testimonial.isActive
                        ? 'text-green-400 hover:bg-green-500/20'
                        : 'text-gray-400 hover:bg-gray-500/20'
                    }`}
                  >
                    <Check className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleEdit(testimonial)}
                    className="p-2 text-blue-400 hover:bg-blue-500/20 rounded-lg transition-colors"
                  >
                    <Edit className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(testimonial.id)}
                    className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}