import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, increment, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Play, Pause, Volume2, VolumeX, Download, Heart, Star, Share2 } from 'lucide-react';
import type { SongRequest, Testimonial } from '../types';
import SongGenerator from './SongGenerator';
import ShareModal from './ShareModal';
import Footer from './Footer';

export default function SongPlayer() {
  const { orderId } = useParams();
  const [song, setSong] = useState<SongRequest | null>(null);
  const [review, setReview] = useState<Testimonial | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.7);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressIntervalRef = useRef<number | null>(null);
  const orderFormRef = useRef<HTMLDivElement>(null);

  const musicNotes = ['♪', '♫', '♬', '♩', '𝄞'];

  useEffect(() => {
    const fetchSongAndLikes = async () => {
      if (!orderId) return;

      try {
        // Get the likes document
        const likesDoc = await getDoc(doc(db, 'songLikes', orderId));
        
        if (likesDoc.exists()) {
          const likesData = likesDoc.data();
          setLikesCount(likesData.count || 0);
          
          // Check local storage for like status
          const likedSongs = JSON.parse(localStorage.getItem('likedSongs') || '[]');
          setIsLiked(likedSongs.includes(orderId));
        } else {
          // Initialize likes document if it doesn't exist
          await setDoc(doc(db, 'songLikes', orderId), {
            count: 0,
            createdAt: new Date()
          });
          setLikesCount(0);
        }
      } catch (err) {
        console.error('Error fetching likes:', err);
      }
    };

    fetchSongAndLikes();
  }, [orderId]);

  const handleLike = async () => {
    if (!orderId) return;

    try {
      const likesRef = doc(db, 'songLikes', orderId);
      const likesDoc = await getDoc(likesRef);
      const likedSongs = JSON.parse(localStorage.getItem('likedSongs') || '[]');

      // If likes document doesn't exist, create it first
      if (!likesDoc.exists()) {
        await setDoc(likesRef, {
          count: 0,
          createdAt: serverTimestamp()
        });
      }

      if (isLiked) {
        // Remove like
        await updateDoc(likesRef, {
          count: increment(-1)
        });
        localStorage.setItem(
          'likedSongs', 
          JSON.stringify(likedSongs.filter((id: string) => id !== orderId))
        );
        setLikesCount(prev => Math.max(0, prev - 1));
      } else {
        // Add like
        await updateDoc(likesRef, {
          count: increment(1)
        });
        localStorage.setItem(
          'likedSongs', 
          JSON.stringify([...likedSongs, orderId])
        );
        setLikesCount(prev => prev + 1);
      }
      
      setIsLiked(!isLiked);
    } catch (err) {
      console.error('Error updating likes:', err);
    }
  };

  useEffect(() => {
    const fetchSongAndReview = async () => {
      try {
        if (!orderId) {
          throw new Error('No order ID provided');
        }

        const songDoc = await getDoc(doc(db, 'songRequests', orderId));
        
        if (!songDoc.exists()) {
          throw new Error('Song not found');
        }

        const songData = {
          id: songDoc.id,
          ...songDoc.data(),
          createdAt: songDoc.data().createdAt.toDate(),
          paidAt: songDoc.data().paidAt?.toDate(),
          completedAt: songDoc.data().completedAt?.toDate(),
        } as SongRequest;

        if (!songData.mp3File) {
          throw new Error('Song file not found');
        }

        setSong(songData);

        const reviewsQuery = query(
          collection(db, 'testimonials'),
          where('orderId', '==', orderId),
          where('isActive', '==', true)
        );

        const reviewsSnapshot = await getDocs(reviewsQuery);
        
        if (!reviewsSnapshot.empty) {
          const reviewData = {
            id: reviewsSnapshot.docs[0].id,
            ...reviewsSnapshot.docs[0].data(),
            createdAt: reviewsSnapshot.docs[0].data().createdAt.toDate()
          } as Testimonial;
          setReview(reviewData);
        }

      } catch (err) {
        console.error('Error fetching song:', err);
        setError(err instanceof Error ? err.message : 'Error loading song');
      } finally {
        setLoading(false);
      }
    };

    fetchSongAndReview();
  }, [orderId]);

  const startProgressInterval = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    progressIntervalRef.current = window.setInterval(() => {
      if (audioRef.current) {
        const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(currentProgress);
      }
    }, 100);
  };

  const togglePlay = async () => {
    if (!audioRef.current) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      } else {
        await audioRef.current.play();
        startProgressInterval();
      }
      setIsPlaying(!isPlaying);
    } catch (err) {
      console.error('Error playing audio:', err);
      setError('Error playing song');
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : newVolume;
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? volume : 0;
    }
  };

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current) return;

    const bar = e.currentTarget;
    const clickPosition = (e.clientX - bar.getBoundingClientRect().left) / bar.offsetWidth;
    const newTime = clickPosition * audioRef.current.duration;
    
    audioRef.current.currentTime = newTime;
    setProgress((newTime / audioRef.current.duration) * 100);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const scrollToOrderForm = () => {
    orderFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Create a single audio instance that persists
  useEffect(() => {
    if (song?.mp3File && !audioRef.current) {
      const audio = new Audio(song.mp3File);
      audioRef.current = audio;
      
      audio.volume = isMuted ? 0 : volume;
      
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
      };

      audio.onended = () => {
        setIsPlaying(false);
        setProgress(0);
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      };

      return () => {
        audio.pause();
        audioRef.current = null;
        if (progressIntervalRef.current) {
          clearInterval(progressIntervalRef.current);
        }
      };
    }
  }, [song]);

  // Separate effect for volume changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : volume;
    }
  }, [volume, isMuted]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0f1117] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500"></div>
      </div>
    );
  }

  if (error || !song) {
    return (
      <div className="min-h-screen bg-[#0f1117] flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-400 mb-4">{error || 'Song not found'}</p>
          <a href="/" className="text-violet-400 hover:text-violet-300">
            Return to homepage
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{song ? `Песен за ${song.recipientName} | Melostory` : 'Melostory'}</title>
        <meta property="og:title" content={song ? `Песен за ${song.recipientName} от ${song.senderName}` : 'Melostory'} />
        <meta property="og:description" content="Чуй тази персонално композирана песен, създадена с любов от Melostory." />
        <meta property="og:image" content={song?.coverImage || 'https://i.imgur.com/UBtGmSL.png'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-[#0f1117]">
        {/* Hero Section with Animation */}
        <div className="relative min-h-screen">
          {/* Animated Notes - Now contained within the hero section */}
          <div className="absolute inset-0 pointer-events-none h-screen overflow-hidden">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="note absolute text-white/10 animate-float"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  fontSize: `${Math.random() * 20 + 14}px`,
                  animationDelay: `${Math.random() * 10}s`,
                  animationDuration: `${8 + Math.random() * 7}s`,
                }}
              >
                {musicNotes[Math.floor(Math.random() * musicNotes.length)]}
              </div>
            ))}
          </div>

          {/* Gradient Overlay - Also contained within hero */}
          <div className="absolute inset-0 pointer-events-none h-screen overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-pink-600/20 via-purple-600/10 to-transparent" />
            <div className="absolute inset-0 bg-gradient-radial from-transparent via-[#0f1117]/50 to-[#0f1117]" />
          </div>

          {/* Player Section */}
          <div className="min-h-screen flex items-center justify-center p-4 pt-24 relative">
            <div className="w-full max-w-md">
              <div className="bg-[#1a1d25] rounded-xl overflow-hidden shadow-xl relative z-10">
                <div className="aspect-square relative">
                  <img
                    src={song.coverImage || 'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bXVzaWN8ZW58MHx8MHx8fDA%3D'}
                    alt="Song Cover"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                </div>

                <div className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <div>
                      <h1 className="text-2xl font-bold mb-1">
                        Песен за {song.recipientName}
                      </h1>
                      <p className="text-gray-400 text-sm">
                        От: {song.senderName}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="relative group">
                        <button
                          onClick={handleLike}
                          className={`p-2 transition-colors ${
                            isLiked 
                              ? 'text-red-400 hover:text-red-300' 
                              : 'text-gray-400 hover:text-red-400'
                          }`}
                        >
                          <Heart className={`w-5 h-5 ${isLiked ? 'fill-current' : ''}`} />
                          <span className="sr-only">{likesCount} likes</span>
                        </button>
                        {/* Tooltip */}
                        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-[#2a2d35] rounded text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                          {likesCount} {likesCount === 1 ? 'харесване' : 'харесвания'}
                        </div>
                      </div>
                      <button
                        onClick={() => setShowShareModal(true)}
                        className="p-2 text-violet-400 hover:text-violet-300 transition-colors"
                      >
                        <Share2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>

                  <div 
                    className="h-1 bg-[#2a2d35] rounded-full cursor-pointer mb-2"
                    onClick={handleProgressClick}
                  >
                    <div 
                      className="h-full bg-violet-500 rounded-full"
                      style={{ width: `${progress}%` }}
                    />
                  </div>

                  <div className="flex justify-between text-sm text-gray-400 mb-4">
                    <span>{audioRef.current ? formatTime(audioRef.current.currentTime) : '0:00'}</span>
                    <span>{duration ? formatTime(duration) : '0:00'}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <button 
                        onClick={toggleMute}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        {isMuted ? (
                          <VolumeX className="w-5 h-5" />
                        ) : (
                          <Volume2 className="w-5 h-5" />
                        )}
                      </button>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={volume}
                        onChange={handleVolumeChange}
                        className="w-20 h-1 bg-[#2a2d35] rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:bg-violet-500 [&::-webkit-slider-thumb]:rounded-full"
                      />
                    </div>

                    <button
                      onClick={togglePlay}
                      className="w-14 h-14 rounded-full bg-violet-500 hover:bg-violet-600 flex items-center justify-center transition-colors"
                    >
                      {isPlaying ? (
                        <Pause className="w-6 h-6" />
                      ) : (
                        <Play className="w-6 h-6 ml-1" />
                      )}
                    </button>

                    <a
                      href={song.mp3File}
                      download
                      className="flex items-center gap-1 text-violet-400 hover:text-violet-300 text-sm"
                    >
                      <Download className="w-4 h-4" />
                      Изтегли
                    </a>
                  </div>
                </div>

                {review && (
                  <div className="p-6 border-t border-gray-800">
                    <div className="flex items-center justify-center gap-1 mb-4">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          className={`w-5 h-5 ${
                            star <= review.rating
                              ? 'text-yellow-400 fill-yellow-400'
                              : 'text-gray-500'
                          }`}
                        />
                      ))}
                    </div>
                    <p className="text-center text-gray-300 mb-4">{review.content}</p>
                    <p className="text-center font-medium">{review.name}</p>
                  </div>
                )}
              </div>

              <div className="mt-8 flex items-center justify-center">
                <button
                  onClick={scrollToOrderForm}
                  className="inline-flex items-center gap-2 bg-violet-500 hover:bg-violet-600 px-6 py-3 rounded-lg font-medium transition-colors"
                >
                  <Heart className="w-5 h-5" />
                  Създай своя песен
                </button>
              </div>
            </div>

            <div className="absolute bottom-8 left-1/2 -translate-x-1/2 animate-bounce">
              <div className="w-6 h-10 border-2 border-gray-400 rounded-full relative">
                <div className="w-1 h-2 bg-gray-400 rounded-full absolute left-1/2 top-2 -translate-x-1/2"></div>
              </div>
            </div>
          </div>
        </div>

        <ShareModal
          isOpen={showShareModal}
          onClose={() => setShowShareModal(false)}
          url={window.location.href}
          title={`Песен за ${song.recipientName} от ${song.senderName}`}
        />

        {/* Order Form Section with Solid Background */}
        <div ref={orderFormRef} className="bg-black relative z-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <SongGenerator />
          </div>
        </div>

        {/* Footer with Solid Background */}
        <div className="bg-black relative z-20">
          <Footer />
        </div>
      </div>
    </>
  );
}