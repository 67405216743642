import React from 'react';

export default function Hero() {
  const scrollToForm = () => {
    const formElement = document.querySelector('#song-generator');
    formElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const musicNotes = ['♪', '♫', '♬', '♩', '𝄞'];

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-[#0f1117]">
      {/* Animated Musical Notes */}
      <div className="absolute inset-0">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="note absolute text-white/20 animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 20 + 14}px`,
              animationDelay: `${Math.random() * 10}s`,
              animationDuration: `${8 + Math.random() * 7}s`,
            }}
          >
            {musicNotes[Math.floor(Math.random() * musicNotes.length)]}
          </div>
        ))}
      </div>

      {/* Gradient Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-pink-600/50 via-purple-600/40 to-transparent" />
        <div className="absolute inset-0 bg-gradient-radial from-transparent via-purple-900/30 to-[#0f1117]" />
      </div>

      {/* Content */}
      <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-8 leading-tight">
          Създай мелодия с история!
        </h1>
        <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto mb-12">
          Персонално композирани песни, които носят емоция. Подари един незабравим подарък на любим човек 
        </p>
        <button 
          onClick={scrollToForm}
          className="bg-[#0f1117] hover:bg-[#1a1d25] text-white px-8 py-4 rounded-full text-lg font-medium transition-all duration-200 transform hover:scale-105 shadow-xl border border-purple-500/20"
        >
          Изпробвай сега
        </button>
      </div>
    </div>
  );
}