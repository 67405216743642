import React, { useEffect, useState } from 'react';
import { Star } from 'lucide-react';
import { collection, query, where, orderBy, getDocs, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Testimonial } from '../types';

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        // Try the compound query first
        const compoundQuery = query(
          collection(db, 'testimonials'),
          where('isActive', '==', true),
          orderBy('createdAt', 'desc'),
          limit(6) // Limit to 6 testimonials
        );

        try {
          const snapshot = await getDocs(compoundQuery);
          const testimonialData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate()
          })) as Testimonial[];
          
          setTestimonials(testimonialData);
          setError(null);
        } catch (err: any) {
          // If compound query fails, try simple query
          if (err.code === 'failed-precondition') {
            const simpleQuery = query(
              collection(db, 'testimonials'),
              where('isActive', '==', true),
              limit(6)
            );
            
            const snapshot = await getDocs(simpleQuery);
            const testimonialData = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            })) as Testimonial[];
            
            setTestimonials(testimonialData);
            setError(null);
          } else {
            throw err;
          }
        }
      } catch (err) {
        console.error('Error fetching testimonials:', err);
        setError('Грешка при зареждане на отзивите');
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500 mx-auto"></div>
          <p className="mt-4 text-gray-400">Зареждане на отзиви...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center text-red-400">{error}</div>
      </div>
    );
  }

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <div className="bg-[#0a0c12] py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">
          Какво казват нашите клиенти
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-[#1a1d25] p-6 rounded-lg"
            >
              <div className="flex items-center gap-1 mb-4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <Star
                    key={star}
                    className={`w-5 h-5 ${
                      star <= testimonial.rating
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-500'
                    }`}
                  />
                ))}
              </div>
              <p className="text-gray-300 mb-4">{testimonial.content}</p>
              <p className="font-medium">{testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}