import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, Volume2, VolumeX, Star } from 'lucide-react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { SampleSong } from '../types';

export default function DiscoverSongs() {
  const [songs, setSongs] = useState<SampleSong[]>([]);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [volume, setVolume] = useState(0.7);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressIntervalRef = useRef<number | null>(null);

  useEffect(() => {
    const q = query(collection(db, 'sampleSongs'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const songData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      })) as SampleSong[];
      
      setSongs(songData);
      setLoading(false);
    }, (err) => {
      console.error('Error fetching songs:', err);
      setError('Error loading songs');
      setLoading(false);
    });

    return () => {
      unsubscribe();
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  const startProgressInterval = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    progressIntervalRef.current = window.setInterval(() => {
      if (audioRef.current) {
        const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(currentProgress);
      }
    }, 100);
  };

  const togglePlay = async (index: number) => {
    try {
      if (playingIndex === index) {
        // Pause current song
        if (audioRef.current) {
          audioRef.current.pause();
          if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
          }
        }
        setPlayingIndex(null);
      } else {
        setError(null);
        
        // Stop previous audio if any
        if (audioRef.current) {
          audioRef.current.pause();
          if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
          }
        }

        // Create and play new audio
        const audio = new Audio(songs[index].mp3Url);
        audioRef.current = audio;
        
        // Set up audio event listeners
        audio.volume = isMuted ? 0 : volume;
        
        audio.onloadedmetadata = () => {
          setDuration(audio.duration);
        };

        audio.onended = () => {
          setPlayingIndex(null);
          setProgress(0);
          if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
          }
        };

        audio.onerror = () => {
          setError('Съжаляваме, възникна проблем при зареждането на песента. Моля, опитайте отново.');
          setPlayingIndex(null);
          if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
          }
        };

        // Play audio
        await audio.play();
        setPlayingIndex(index);
        startProgressInterval();
      }
    } catch (err) {
      setError('Съжаляваме, възникна проблем при зареждането на песента. Моля, опитайте отново.');
      setPlayingIndex(null);
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : newVolume;
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? volume : 0;
    }
  };

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current) return;

    const bar = e.currentTarget;
    const clickPosition = (e.clientX - bar.getBoundingClientRect().left) / bar.offsetWidth;
    const newTime = clickPosition * audioRef.current.duration;
    
    audioRef.current.currentTime = newTime;
    setProgress((newTime / audioRef.current.duration) * 100);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-24">
      <div className="text-center mb-12">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4">
          Докоснете се до магията на Мелостори  🎧
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Насладете се на разнообразие от песни, създадени за специални моменти. Натисни плей и усети качеството, персоналния подход и емоционалното въздействие, които правят всяка песен незабравима.
        </p>
        {error && (
          <div className="mt-4 text-red-400 bg-red-400/10 p-3 rounded-lg">
            {error}
          </div>
        )}
      </div>

      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500 mx-auto"></div>
          <p className="mt-4 text-gray-400">Зареждане на песни...</p>
        </div>
      ) : songs.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-400">Все още няма добавени примерни песни.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {songs.map((song, index) => (
            <div 
              key={song.id} 
              className="bg-[#1a1d25] rounded-xl overflow-hidden group hover:bg-[#1e2129] transition-colors"
            >
              <div className="relative">
                <img
                  src={song.imageUrl}
                  alt={song.name}
                  className="w-full aspect-[4/3] object-cover"
                />
                <button 
                  onClick={() => togglePlay(index)}
                  className="absolute inset-0 w-full h-full flex items-center justify-center bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <div className="bg-white/10 backdrop-blur-sm p-4 rounded-full">
                    {playingIndex === index ? (
                      <Pause className="w-6 h-6 text-white" />
                    ) : (
                      <Play className="w-6 h-6 text-white" fill="white" />
                    )}
                  </div>
                </button>
              </div>
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm text-violet-400">{song.genre}</span>
                  <div className="flex items-center gap-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`w-4 h-4 ${
                          star <= (song.rating || 0)
                            ? 'text-yellow-400 fill-yellow-400'
                            : 'text-gray-500'
                        }`}
                      />
                    ))}
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2">{song.name}</h3>
                
                {playingIndex === index && (
                  <div className="space-y-2">
                    <div 
                      className="h-1 bg-[#2a2d35] rounded-full cursor-pointer"
                      onClick={handleProgressClick}
                    >
                      <div 
                        className="h-full bg-violet-500 rounded-full"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                    
                    <div className="flex items-center justify-between">
                      <span className="text-xs text-gray-400">
                        {audioRef.current ? formatTime(audioRef.current.currentTime) : '0:00'} / 
                        {duration ? formatTime(duration) : '0:00'}
                      </span>
                      
                      <div className="flex items-center gap-2">
                        <button 
                          onClick={toggleMute}
                          className="text-gray-400 hover:text-white transition-colors"
                        >
                          {isMuted ? (
                            <VolumeX className="w-4 h-4" />
                          ) : (
                            <Volume2 className="w-4 h-4" />
                          )}
                        </button>
                        <input
                          type="range"
                          min="0"
                          max="1"
                          step="0.01"
                          value={volume}
                          onChange={handleVolumeChange}
                          className="w-20 h-1 bg-[#2a2d35] rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:bg-violet-500 [&::-webkit-slider-thumb]:rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}