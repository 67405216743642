import React, { useState } from 'react';
import { X, Star } from 'lucide-react';
import { db } from '../lib/firebase';
import { collection, addDoc } from 'firebase/firestore';

interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (orderId: string) => void;
  orderId: string;
  recipientName: string;
}

export default function ReviewModal({ isOpen, onClose, onSubmit, orderId, recipientName }: ReviewModalProps) {
  const [rating, setRating] = useState(5);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Validate inputs
      if (!name.trim() || !content.trim()) {
        throw new Error('Моля, попълнете всички полета');
      }

      // Create testimonial
      await addDoc(collection(db, 'testimonials'), {
        name,
        content,
        rating,
        orderId,
        isActive: false, // Requires admin approval
        createdAt: new Date()
      });

      // Notify parent component about successful submission
      onSubmit(orderId);
    } catch (err) {
      console.error('Error submitting review:', err);
      setError(err instanceof Error ? err.message : 'Грешка при изпращане на отзива');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1a1d25] p-8 rounded-xl w-full max-w-md relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">
          Остави отзив за песента
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/20 text-red-400 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Име</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Рейтинг</label>
            <div className="flex justify-center gap-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => setRating(star)}
                  className="text-2xl focus:outline-none"
                >
                  <Star
                    className={`w-6 h-6 ${
                      star <= rating
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-500'
                    }`}
                  />
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Отзив</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500 h-32 resize-none"
              placeholder={`Разкажете ни как се чувствате с песента за ${recipientName}`}
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 rounded-lg font-medium transition-colors ${
              loading 
                ? 'bg-violet-500/50 cursor-not-allowed' 
                : 'bg-violet-500 hover:bg-violet-600'
            }`}
          >
            {loading ? 'Изпращане...' : 'Изпрати отзив'}
          </button>
        </form>
      </div>
    </div>
  );
}