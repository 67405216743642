import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../lib/firebase';
import { Music, Users, MessageSquare, Menu, X } from 'lucide-react';
import OrderManager from './OrderManager';
import SongManager from './SongManager';
import TestimonialManager from './TestimonialManager';
import Statistics from './Statistics';
import AdminLogin from './AdminLogin';

export default function AdminPage() {
  const [activeTab, setActiveTab] = useState<'orders' | 'songs' | 'testimonials'>('orders');
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setIsAdmin(false);
        setLoading(false);
        navigate('/admin/login');
        return;
      }

      try {
        // Force token refresh to get latest claims
        const idTokenResult = await user.getIdTokenResult(true);
        
        if (!idTokenResult.claims.admin) {
          setIsAdmin(false);
          setLoading(false);
          navigate('/admin/login');
          return;
        }

        setIsAdmin(true);
        setLoading(false);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
        setLoading(false);
        navigate('/admin/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleTabChange = (tab: 'orders' | 'songs' | 'testimonials') => {
    setActiveTab(tab);
    setIsMobileMenuOpen(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0f1117] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500"></div>
      </div>
    );
  }

  if (!isAdmin) {
    return <AdminLogin />;
  }

  return (
    <div className="min-h-screen bg-[#0f1117]">
      {/* Mobile Header */}
      <div className="lg:hidden fixed top-0 left-0 right-0 z-50 bg-[#1a1d25] border-b border-gray-800">
        <div className="flex items-center justify-between p-4">
          <h1 className="text-lg font-semibold">Админ Панел</h1>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 text-gray-400 hover:text-white"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="border-t border-gray-800">
            <button
              onClick={() => handleTabChange('orders')}
              className={`flex items-center gap-2 w-full p-4 ${
                activeTab === 'orders'
                  ? 'bg-violet-500 text-white'
                  : 'text-gray-400 hover:bg-[#2a2d35]'
              }`}
            >
              <Users className="w-5 h-5" />
              Поръчки
            </button>
            <button
              onClick={() => handleTabChange('songs')}
              className={`flex items-center gap-2 w-full p-4 ${
                activeTab === 'songs'
                  ? 'bg-violet-500 text-white'
                  : 'text-gray-400 hover:bg-[#2a2d35]'
              }`}
            >
              <Music className="w-5 h-5" />
              Примерни песни
            </button>
            <button
              onClick={() => handleTabChange('testimonials')}
              className={`flex items-center gap-2 w-full p-4 ${
                activeTab === 'testimonials'
                  ? 'bg-violet-500 text-white'
                  : 'text-gray-400 hover:bg-[#2a2d35]'
              }`}
            >
              <MessageSquare className="w-5 h-5" />
              Отзиви
            </button>
          </div>
        )}
      </div>

      {/* Desktop Navigation */}
      <div className="hidden lg:block">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-8">
          <h1 className="text-2xl font-bold mb-8">Админ Панел</h1>
          <div className="flex gap-4">
            <button
              onClick={() => setActiveTab('orders')}
              className={`flex items-center gap-2 px-8 py-4 rounded-lg font-medium transition-colors ${
                activeTab === 'orders'
                  ? 'bg-violet-500 text-white'
                  : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
              }`}
            >
              <Users className="w-5 h-5" />
              Поръчки
            </button>
            <button
              onClick={() => setActiveTab('songs')}
              className={`flex items-center gap-2 px-8 py-4 rounded-lg font-medium transition-colors ${
                activeTab === 'songs'
                  ? 'bg-violet-500 text-white'
                  : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
              }`}
            >
              <Music className="w-5 h-5" />
              Примерни песни
            </button>
            <button
              onClick={() => setActiveTab('testimonials')}
              className={`flex items-center gap-2 px-8 py-4 rounded-lg font-medium transition-colors ${
                activeTab === 'testimonials'
                  ? 'bg-violet-500 text-white'
                  : 'bg-[#1a1d25] text-gray-300 hover:bg-[#2a2d35]'
              }`}
            >
              <MessageSquare className="w-5 h-5" />
              Отзиви
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobileMenuOpen ? 'hidden' : 'block'} mt-20 lg:mt-0`}>
        {activeTab === 'orders' && (
          <>
            <div className="mb-8">
              <Statistics />
            </div>
            <OrderManager />
          </>
        )}
        {activeTab === 'songs' && <SongManager />}
        {activeTab === 'testimonials' && <TestimonialManager />}
      </div>
    </div>
  );
}