import React from 'react';

const steps = [
  {
    number: '1',
    title: 'Избери как да звучи',
    description: 'Посочи от различни музикални жанрове, език и кой да изпее песента.',
  },
  {
    number: '2',
    title: 'Сподели какво да съдържа',
    description: 'Разкажи ни за специалния човек или момент, който ще присъства в песента..',
  },
  {
    number: '3',
    title: 'Изненадай любим човек',
    description: 'Чуй твоята песен онлайн и я изтегли в MP3 файл, за да я слушате и споделяте с любимите си хора.',
  },
];

export default function HowToUse() {
  return (
    <div className="bg-[#0a0c12] py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">
          Персонално композирана песен в три лесни стъпки!
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step) => (
            <div
              key={step.number}
              className="bg-[#1a1d25] p-8 rounded-lg"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="flex items-center justify-center w-8 h-8 bg-violet-500 rounded-full shrink-0">
                  <span className="text-base font-bold">{step.number}</span>
                </div>
                <h3 className="text-xl font-semibold">{step.title}</h3>
              </div>
              <p className="text-gray-400">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}