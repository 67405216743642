import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if user has already accepted cookies
    const hasAccepted = localStorage.getItem('cookieConsent');
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handlePreferences = () => {
    // Here you can implement cookie preferences functionality
    // For now, we'll just accept all cookies
    handleAccept();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#1a1d25] border-t border-gray-800 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-300">
          Този уебсайт използва бисквитки, за да гарантира, че получавате най-доброто изживяване на нашия уебсайт.{' '}
          <Link to="/privacy" className="text-violet-400 hover:text-violet-300">
            Правила за поверителност
          </Link>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handlePreferences}
            className="px-4 py-2 text-sm font-medium rounded-lg border border-violet-500 text-violet-400 hover:bg-violet-500/10 transition-colors"
          >
            Предпочитания
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 text-sm font-medium rounded-lg bg-violet-500 hover:bg-violet-600 transition-colors"
          >
            Приемам
          </button>
        </div>
      </div>
    </div>
  );
}