import React, { useState } from 'react';
import { X } from 'lucide-react';
import { auth, db } from '../../lib/firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, formData.email);
      setSuccess('Изпратихме ви имейл с инструкции за възстановяване на паролата.');
      setFormData(prev => ({ ...prev, email: '' }));
    } catch (err: any) {
      let errorMessage = 'Възникна грешка. Моля, опитайте отново.';
      if (err.code === 'auth/user-not-found') {
        errorMessage = 'Не съществува потребител с този имейл адрес.';
      } else if (err.code === 'auth/invalid-email') {
        errorMessage = 'Невалиден имейл адрес.';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      if (!isLogin) {
        // Validate passwords match for registration
        if (formData.password !== formData.confirmPassword) {
          setError('Паролите не съвпадат');
          setLoading(false);
          return;
        }

        // Register
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        
        // Update profile with name
        await updateProfile(userCredential.user, {
          displayName: formData.name
        });

        // Store additional user data in Firestore
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          createdAt: new Date()
        });
      } else {
        // Login
        await signInWithEmailAndPassword(auth, formData.email, formData.password);
      }
      onClose();
      // Redirect to dashboard after successful login/register
      navigate('/dashboard');
    } catch (err: any) {
      let errorMessage = 'Възникна грешка. Моля, опитайте отново.';
      if (err.code === 'auth/email-already-in-use') {
        errorMessage = 'Потребител с този имейл вече е регистриран.';
      } else if (err.code === 'auth/invalid-email') {
        errorMessage = 'Невалиден имейл адрес.';
      } else if (err.code === 'auth/weak-password') {
        errorMessage = 'Паролата трябва да е поне 6 символа.';
      } else if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        errorMessage = 'Грешен имейл или парола.';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1a1d25] p-8 rounded-xl w-full max-w-md relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">
          {isForgotPassword ? 'Забравена парола' : isLogin ? 'Вход' : 'Регистрация'}
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/20 text-red-400 rounded-lg">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-3 bg-green-500/20 text-green-400 rounded-lg">
            {success}
          </div>
        )}

        {isForgotPassword ? (
          <form onSubmit={handleForgotPassword} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Имейл</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-3 rounded-lg font-medium transition-colors ${
                loading 
                  ? 'bg-violet-500/50 cursor-not-allowed' 
                  : 'bg-violet-500 hover:bg-violet-600'
              }`}
            >
              {loading ? 'Изпращане...' : 'Изпрати инструкции'}
            </button>

            <button
              type="button"
              onClick={() => {
                setIsForgotPassword(false);
                setError(null);
                setSuccess(null);
              }}
              className="mt-4 text-violet-400 hover:text-violet-300 text-sm"
            >
              Обратно към вход
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            {!isLogin && (
              <div>
                <label className="block text-sm font-medium mb-1">Име</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required={!isLogin}
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium mb-1">Имейл</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                required
              />
            </div>

            {!isLogin && (
              <div>
                <label className="block text-sm font-medium mb-1">Тел. номер</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium mb-1">
                {isLogin ? 'Парола' : 'Създай парола'}
              </label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                required
              />
            </div>

            {!isLogin && (
              <div>
                <label className="block text-sm font-medium mb-1">Повтори парола</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="w-full p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-3 rounded-lg font-medium transition-colors ${
                loading 
                  ? 'bg-violet-500/50 cursor-not-allowed' 
                  : 'bg-violet-500 hover:bg-violet-600'
              }`}
            >
              {loading ? 'Зареждане...' : isLogin ? 'Вход' : 'Регистрация'}
            </button>

            <div className="flex flex-col gap-2 text-center">
              <button
                type="button"
                onClick={() => {
                  setIsLogin(!isLogin);
                  setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmPassword: ''
                  });
                  setError(null);
                }}
                className="text-violet-400 hover:text-violet-300 text-sm"
              >
                {isLogin 
                  ? 'Нямате акаунт? Регистрирайте се' 
                  : 'Вече имате акаунт? Влезте'}
              </button>

              {isLogin && (
                <button
                  type="button"
                  onClick={() => {
                    setIsForgotPassword(true);
                    setError(null);
                    setSuccess(null);
                  }}
                  className="text-violet-400 hover:text-violet-300 text-sm"
                >
                  Забравена парола?
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}