import React, { useState, useEffect } from 'react';
import { db } from '../../lib/firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { BarChart, Calendar, TrendingUp, DollarSign } from 'lucide-react';

interface OrderStats {
  totalOrders: number;
  totalRevenue: number;
  pendingOrders: number;
  completedOrders: number;
}

type TimeFilter = 'all' | 'day' | 'week' | 'month';

// Constants for order prices
const ORDER_PRICES = {
  express: 153,
  normal: 93
};

export default function Statistics() {
  const [stats, setStats] = useState<OrderStats>({
    totalOrders: 0,
    totalRevenue: 0,
    pendingOrders: 0,
    completedOrders: 0
  });
  const [timeFilter, setTimeFilter] = useState<TimeFilter>('day');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchStats();
  }, [timeFilter]);

  const getTimeRange = () => {
    if (timeFilter === 'all') {
      return null; // No time range filter for 'all'
    }

    const now = new Date();
    const start = new Date();

    switch (timeFilter) {
      case 'day':
        start.setHours(0, 0, 0, 0);
        break;
      case 'week':
        start.setDate(now.getDate() - 7);
        break;
      case 'month':
        start.setMonth(now.getMonth() - 1);
        break;
    }

    return {
      start: Timestamp.fromDate(start),
      end: Timestamp.fromDate(now)
    };
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      const timeRange = getTimeRange();
      
      const ordersRef = collection(db, 'songRequests');
      let q;

      if (timeRange) {
        q = query(
          ordersRef,
          where('createdAt', '>=', timeRange.start),
          where('createdAt', '<=', timeRange.end)
        );
      } else {
        // For 'all' filter, fetch all orders without time constraints
        q = query(ordersRef);
      }

      const querySnapshot = await getDocs(q);
      
      let totalOrders = 0;
      let totalRevenue = 0;
      let pendingOrders = 0;
      let completedOrders = 0;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        totalOrders++;
        
        if (data.paidStatus) {
          // Calculate revenue based on order type
          totalRevenue += data.orderType === 'express' 
            ? ORDER_PRICES.express 
            : ORDER_PRICES.normal;
        }
        
        if (!data.mp3File) {
          pendingOrders++;
        } else {
          completedOrders++;
        }
      });

      setStats({
        totalOrders,
        totalRevenue,
        pendingOrders,
        completedOrders
      });
    } catch (err) {
      console.error('Error fetching stats:', err);
      setError('Грешка при зареждане на статистиката');
    } finally {
      setLoading(false);
    }
  };

  const StatCard = ({ 
    title, 
    value, 
    icon: Icon,
    color
  }: { 
    title: string; 
    value: string | number;
    icon: React.ElementType;
    color: string;
  }) => (
    <div className="bg-[#2a2d35] p-6 rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <div className={`p-3 rounded-lg ${color}`}>
          <Icon className="w-6 h-6" />
        </div>
      </div>
      <h3 className="text-lg font-medium text-gray-400 mb-2">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );

  return (
    <div className="mb-8">
      <div className="flex flex-col gap-4 mb-6">
        <h2 className="text-xl font-semibold">Статистика на поръчките</h2>
        
        {/* Mobile Filter */}
        <div className="flex md:hidden overflow-x-auto pb-2 -mx-4 px-4">
          <div className="flex gap-2 min-w-full">
            {(['all', 'day', 'week', 'month'] as TimeFilter[]).map((filter) => (
              <button
                key={filter}
                onClick={() => setTimeFilter(filter)}
                className={`flex-1 px-3 py-2 rounded-lg text-sm whitespace-nowrap transition-colors ${
                  timeFilter === filter
                    ? 'bg-violet-500 text-white'
                    : 'bg-[#2a2d35] text-gray-400 hover:text-white'
                }`}
              >
                {filter === 'all' && 'Всички'}
                {filter === 'day' && 'Ден'}
                {filter === 'week' && 'Седмица'}
                {filter === 'month' && 'Месец'}
              </button>
            ))}
          </div>
        </div>

        {/* Desktop Filter */}
        <div className="hidden md:flex justify-end">
          <div className="flex gap-2 bg-[#2a2d35] rounded-lg p-1">
            {(['all', 'day', 'week', 'month'] as TimeFilter[]).map((filter) => (
              <button
                key={filter}
                onClick={() => setTimeFilter(filter)}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  timeFilter === filter
                    ? 'bg-violet-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                {filter === 'all' && 'Всички'}
                {filter === 'day' && 'Ден'}
                {filter === 'week' && 'Седмица'}
                {filter === 'month' && 'Месец'}
              </button>
            ))}
          </div>
        </div>
      </div>

      {error ? (
        <div className="bg-red-500/20 text-red-400 p-4 rounded-lg">
          {error}
        </div>
      ) : loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[...Array(4)].map((_, i) => (
            <div 
              key={i}
              className="bg-[#2a2d35] p-6 rounded-lg animate-pulse"
            >
              <div className="h-10 w-10 bg-gray-700 rounded-lg mb-4"></div>
              <div className="h-4 w-24 bg-gray-700 rounded mb-2"></div>
              <div className="h-8 w-16 bg-gray-700 rounded"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard
            title="Общо поръчки"
            value={stats.totalOrders}
            icon={BarChart}
            color="bg-blue-500/20 text-blue-500"
          />
          <StatCard
            title="Приходи"
            value={`${stats.totalRevenue} лв.`}
            icon={DollarSign}
            color="bg-green-500/20 text-green-500"
          />
          <StatCard
            title="Чакащи поръчки"
            value={stats.pendingOrders}
            icon={Calendar}
            color="bg-yellow-500/20 text-yellow-500"
          />
          <StatCard
            title="Завършени поръчки"
            value={stats.completedOrders}
            icon={TrendingUp}
            color="bg-violet-500/20 text-violet-500"
          />
        </div>
      )}
    </div>
  );
}