import React from 'react';

export default function MakingSongs() {
  const scrollToForm = () => {
    const formElement = document.querySelector('#song-generator');
    formElement?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-24">
      <div className="flex flex-col lg:flex-row items-center gap-12">
        <div className="flex-1 text-center lg:text-left">
          <h2 className="text-3xl font-bold mb-4">Създаването на музика никога не е било по-лесно! </h2>
          <p className="text-gray-400 mb-8">
         Не се нуждаеш от опит в композирането на мелодии, свиренето на инструменти и певчески умения. Ние ще направим това за теб бързо и лесно! В рамките на 72 часа ще получиш персонална песен готова за споделяне.
          </p>
          <button 
            onClick={scrollToForm}
            className="bg-violet-500 hover:bg-violet-600 px-6 py-3 rounded-lg font-medium transition-colors"
          >
            Поръчай песен
          </button>
        </div>
        <div className="flex-1 w-full mb-16 lg:mb-0">
          <img
            src="https://images.unsplash.com/photo-1621586556026-98b104442283?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Човек, наслаждаващ се на музика"
            className="rounded-lg w-full"
          />
        </div>
      </div>
    </div>
  );
}