export function formatDate(date: Date) {
  return new Date(date).toLocaleDateString('bg-BG', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function getStatusLabel(order: { paidStatus: boolean, mp3File?: string }) {
  if (!order.paidStatus) {
    return 'Очаква плащане';
  }
  if (order.paidStatus && !order.mp3File) {
    return 'В процес на изработка';
  }
  return 'Завършена';
}

export function getStatusStyle(order: { paidStatus: boolean, mp3File?: string }) {
  if (!order.paidStatus) {
    return 'bg-yellow-500/20 text-yellow-400';
  }
  if (order.paidStatus && !order.mp3File) {
    return 'bg-blue-500/20 text-blue-400';
  }
  return 'bg-green-500/20 text-green-400';
}