import React from 'react';
import { Check, X } from 'lucide-react';

const features = {
  melostory: [
    'Внимание към детайла',
    'Правилно звучащи фрази и думи',
    'Запомнящ се припев',
    'Смислени куплети',
    'Професионален редактор оформя песента',
    '98 % от клиентите харесват песните си'
  ],
  others: [
    'Суров генериран текст без редакция от човек',
    'Неправилно произношение на думите',
    'Нямат смислова връзка между куплетите',
    'Дълго и скучно интро',
    'Не разказват история',
    'Разминаване между очаквания и крайния резултат'
  ]
};

export default function Comparison() {
  return (
    <div className="bg-[#0a0c12] py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">
          Защо да изберете нас?
        </h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Melostory Column */}
          <div className="bg-[#1a1d25] p-8 rounded-lg border border-violet-500/20">
            <h3 className="text-2xl font-semibold mb-8 text-center text-violet-400">
              Мелостори
            </h3>
            <ul className="space-y-4">
              {features.melostory.map((feature, index) => (
                <li key={index} className="flex items-start gap-3">
                  <Check className="w-5 h-5 text-green-400 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Others Column */}
          <div className="bg-[#1a1d25] p-8 rounded-lg border border-red-500/20">
            <h3 className="text-2xl font-semibold mb-8 text-center text-red-400">
              Други сайтове за генериране на песни
            </h3>
            <ul className="space-y-4">
              {features.others.map((feature, index) => (
                <li key={index} className="flex items-start gap-3">
                  <X className="w-5 h-5 text-red-400 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}