import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Menu, X } from 'lucide-react';
import { auth } from './lib/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Hero from './components/Hero';
import SongGenerator from './components/SongGenerator';
import DiscoverSongs from './components/DiscoverSongs';
import MakingSongs from './components/MakingSongs';
import HowToUse from './components/HowToUse';
import Comparison from './components/Comparison';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AdminPage from './components/Admin/AdminPage';
import AdminLogin from './components/Admin/AdminLogin';
import AuthModal from './components/Auth/AuthModal';
import DashboardLayout from './components/DashboardLayout';
import MyOrders from './components/dashboard/MyOrders';
import OrderSong from './components/dashboard/OrderSong';
import Settings from './components/dashboard/Settings';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookieConsent from './components/CookieConsent';
import SongPlayer from './components/SongPlayer';
import { initFacebookPixel } from './lib/facebook-pixel';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [user, setUser] = useState(auth.currentUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Check for Stripe success or cancel parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stripeStatus = searchParams.get('stripe');
    
    if (stripeStatus === 'success' && user) {
      navigate('/dashboard');
    } else if (stripeStatus === 'cancel') {
      navigate('/');
    }
  }, [location, user, navigate]);

  const handleLogin = () => {
    setIsAuthModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      if (location.pathname === '/dashboard') {
        navigate('/');
      }
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <nav className="absolute w-full top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div 
              className="flex items-center cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img 
                src="https://i.imgur.com/UBtGmSL.png" 
                alt="MeloStory Logo" 
                className="h-12 w-auto"
              />
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-4">
              {user ? (
                <>
                  <button 
                    onClick={() => navigate('/dashboard')}
                    className="text-violet-400 hover:text-violet-300"
                  >
                    Профилът на {user.displayName || 'потребител'}
                  </button>
                  <button 
                    onClick={handleLogout}
                    className="bg-violet-500 hover:bg-violet-600 px-4 py-2 rounded-lg transition-colors"
                  >
                    Изход
                  </button>
                </>
              ) : (
                <button 
                  onClick={handleLogin}
                  className="bg-violet-500 hover:bg-violet-600 px-4 py-2 rounded-lg transition-colors"
                >
                  Вход
                </button>
              )}
            </div>

            {/* Mobile Menu Button */}
            <button 
              className="md:hidden p-2"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-[#0f1117]/95 border-b border-gray-800/50 z-50">
            <div className="px-4 py-2">
              {user ? (
                <>
                  <button 
                    onClick={() => {
                      navigate('/dashboard');
                      setIsMenuOpen(false);
                    }}
                    className="block w-full text-left text-violet-400 hover:text-violet-300 mb-2"
                  >
                    Профилът на {user.displayName || 'потребител'}
                  </button>
                  <button 
                    onClick={handleLogout}
                    className="block w-full bg-violet-500 hover:bg-violet-600 px-4 py-2 rounded-lg text-center transition-colors"
                  >
                    Изход
                  </button>
                </>
              ) : (
                <button 
                  onClick={handleLogin}
                  className="block w-full bg-violet-500 hover:bg-violet-600 px-4 py-2 rounded-lg text-center transition-colors"
                >
                  Вход
                </button>
              )}
            </div>
          </div>
        )}
      </nav>

      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />
    </>
  );
}

function HomePage() {
  return (
    <>
      <main>
        <Hero />
        <DiscoverSongs />
        <MakingSongs />
        <HowToUse />
        <Comparison />
        <SongGenerator />
        <Testimonials />
        <FAQ />
      </main>
      <Footer />
    </>
  );
}

export default function App() {
  useEffect(() => {
    initFacebookPixel();
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-[#0f1117] text-white">
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<MyOrders />} />
              <Route path="orders" element={<MyOrders />} />
              <Route path="new-order" element={<OrderSong />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/song/:orderId" element={<SongPlayer />} />
          </Routes>
          <CookieConsent />
        </div>
      </Router>
    </HelmetProvider>
  );
}