import React from 'react';
import { X, Facebook, Instagram, Apple as WhatsApp, Link2, Mail } from 'lucide-react';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  title: string;
}

export default function ShareModal({ isOpen, onClose, url, title }: ShareModalProps) {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const shareLinks = [
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/dialog/share?app_id=409300361362867&display=popup&href=${encodedUrl}&quote=${encodedTitle}&redirect_uri=${encodedUrl}`,
      color: 'bg-[#1877F2]/10 text-[#1877F2] hover:bg-[#1877F2]/20'
    },
    {
      name: 'Instagram',
      icon: Instagram,
      url: `instagram://story-camera`,
      color: 'bg-[#E4405F]/10 text-[#E4405F] hover:bg-[#E4405F]/20',
      onClick: () => {
        // On mobile, try to open Instagram app
        if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          window.location.href = 'instagram://story-camera';
          // Fallback to Instagram website after a short delay
          setTimeout(() => {
            window.location.href = 'https://instagram.com';
          }, 2000);
        } else {
          // On desktop, open Instagram website
          window.open('https://instagram.com', '_blank');
        }
      }
    },
    {
      name: 'WhatsApp',
      icon: WhatsApp,
      url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`,
      color: 'bg-[#25D366]/10 text-[#25D366] hover:bg-[#25D366]/20'
    },
    {
      name: 'Email',
      icon: Mail,
      url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}`,
      color: 'bg-violet-500/10 text-violet-500 hover:bg-violet-500/20'
    }
  ];

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Линкът е копиран!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1a1d25] p-8 rounded-xl w-full max-w-md relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">
          Сподели песента
        </h2>

        <div className="grid grid-cols-2 gap-4 mb-6">
          {shareLinks.map((link) => (
            <button
              key={link.name}
              onClick={(e) => {
                e.preventDefault();
                if (link.onClick) {
                  link.onClick();
                } else {
                  window.open(
                    link.url,
                    'share-dialog',
                    'width=800,height=600,toolbar=no,location=no'
                  );
                }
              }}
              className={`flex items-center justify-center gap-2 p-4 rounded-lg transition-colors ${link.color}`}
            >
              <link.icon className="w-5 h-5" />
              {link.name}
            </button>
          ))}
        </div>

        <div className="flex items-center gap-2">
          <input
            type="text"
            value={url}
            readOnly
            className="flex-1 p-3 bg-[#2a2d35] rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
          <button
            onClick={copyToClipboard}
            className="p-3 bg-violet-500/10 text-violet-500 hover:bg-violet-500/20 rounded-lg transition-colors"
          >
            <Link2 className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}