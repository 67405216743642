import React, { useState, useEffect } from 'react';
import { HelpCircle } from 'lucide-react';
import { auth } from '../lib/firebase';
import OrderCompletionModal from './OrderCompletionModal';

interface FormState {
  genre: string;
  language: string;
  mood: string;
  voice: string;
  recipientName: string;
  occasion: string;
  description: string;
  senderName: string;
  specificPhrase: string;
  additionalInfo: string;
}

const STORAGE_KEY = 'songGeneratorForm';

const genreOptions = [
  { value: 'surprise', label: 'Изненада!', emoji: '🎲 🎵' },
  { value: 'pop', label: 'Поп', emoji: '🎤 ✨' },
  { value: 'rnb', label: 'R&B', emoji: '🎵 ❤️' },
  { value: 'rock', label: 'Рок', emoji: '🎸 🤘' },
  { value: 'country', label: 'Кънтри', emoji: '🤠 🎻' },
  { value: 'reggae', label: 'Реге', emoji: '🎵 🌴' },
];

const languageOptions = [
  { value: 'bulgarian', label: 'Български', emoji: 'Bg' },
  { value: 'english', label: 'English', emoji: 'En' },
];

const moodOptions = [
  { value: 'romantic', label: 'Романтично', emoji: '💝 ✨' },
  { value: 'fun', label: 'Забавно', emoji: '😄 🎉' },
  { value: 'nostalgic', label: 'Носталгично', emoji: '🌅 💭' },
  { value: 'sad', label: 'Тъжно', emoji: '💔 🌧️' },
  { value: 'inspiring', label: 'Вдъхновяващо', emoji: '⭐ 🌟' },
  { value: 'mysterious', label: 'Драматично', emoji: '🎭 ⚡' },
];

const voiceOptions = [
  { value: 'female', label: 'Женски', emoji: '👩 🎤' },
  { value: 'male', label: 'Мъжки', emoji: '👨 🎤' },
  { value: 'surprise', label: 'Изненада', emoji: '🎲 🎵' },
];

export default function SongGenerator() {
  const [form, setForm] = useState<FormState>(() => {
    // Try to load saved form data from localStorage
    const savedForm = localStorage.getItem(STORAGE_KEY);
    if (savedForm) {
      try {
        return JSON.parse(savedForm);
      } catch (e) {
        console.error('Error parsing saved form data:', e);
      }
    }
    // Return default form state if no saved data exists or parsing fails
    return {
      genre: '',
      language: '',
      mood: '',
      voice: '',
      recipientName: '',
      occasion: '',
      description: '',
      senderName: '',
      specificPhrase: '',
      additionalInfo: '',
    };
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [orderType, setOrderType] = useState<'normal' | 'express'>('normal');
  const [showHelpText, setShowHelpText] = useState(false);

  // Save form data whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(form));
  }, [form]);

  const handleGenreSelect = (value: string) => {
    setForm(prev => ({ ...prev, genre: value }));
    setSubmitStatus({ type: null, message: '' });
  };

  const handleLanguageSelect = (value: string) => {
    setForm(prev => ({ ...prev, language: value }));
    setSubmitStatus({ type: null, message: '' });
  };

  const handleMoodSelect = (value: string) => {
    setForm(prev => ({ ...prev, mood: value }));
    setSubmitStatus({ type: null, message: '' });
  };

  const handleVoiceSelect = (value: string) => {
    setForm(prev => ({ ...prev, voice: value }));
    setSubmitStatus({ type: null, message: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
    setSubmitStatus({ type: null, message: '' });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate required fields
    const requiredFields = ['genre', 'language', 'mood', 'voice', 'recipientName', 'occasion', 'description', 'senderName'];
    const missingFields = requiredFields.filter(field => !form[field as keyof FormState]);
    
    if (missingFields.length > 0) {
      setSubmitStatus({
        type: 'error',
        message: 'Моля, попълнете всички задължителни полета.',
      });
      return;
    }

    // Show completion modal
    setShowCompletionModal(true);
  };

  const handleOrderComplete = async () => {
    setIsSubmitting(true);
    setSubmitStatus({ type: null, message: '' });

    try {
      setSubmitStatus({
        type: 'success',
        message: 'Поръчката е изпратена успешно!',
      });

      // Clear saved form data after successful submission
      localStorage.removeItem(STORAGE_KEY);

      // Reset form
      setForm({
        genre: '',
        language: '',
        mood: '',
        voice: '',
        recipientName: '',
        occasion: '',
        description: '',
        senderName: '',
        specificPhrase: '',
        additionalInfo: '',
      });

      setShowCompletionModal(false);
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'Възникна грешка при изпращането. Моля, опитайте отново.',
      });
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="song-generator" className="text-center max-w-3xl mx-auto pt-12 sm:pt-24 px-4">
      <h1 className="text-3xl sm:text-4xl font-bold mb-4 gradient-text">
       Подари емоция, която ще се помни!
      </h1>
      <p className="text-gray-400 mb-8 sm:mb-12 text-sm sm:text-base">
       Избери своите предпочитания за стил и съдържание на песента. Добави щипка уникална креативност, а останалото ще направим ние!
      </p>

      <form onSubmit={handleSubmit} className="bg-[#1a1d25] p-4 sm:p-8 rounded-xl space-y-6">
        <div className="space-y-3">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            1. Какъв жанр искате за вашата песен?*
          </label>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
            {genreOptions.map((genre) => (
              <button
                key={genre.value}
                type="button"
                onClick={() => handleGenreSelect(genre.value)}
                className={`p-2 rounded-lg border transition-all duration-200 flex flex-col items-center justify-center gap-0.5 ${
                  form.genre === genre.value
                    ? 'border-violet-500 bg-violet-500/20 text-white'
                    : 'border-gray-700 hover:border-violet-500/50 bg-[#2a2d35] text-gray-300 hover:text-white'
                }`}
              >
                <span className="text-base">{genre.emoji}</span>
                <span className="text-xs font-medium whitespace-nowrap">{genre.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-3">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            2. На какъв език искате да бъде песента?*
          </label>
          <div className="grid grid-cols-2 gap-2">
            {languageOptions.map((language) => (
              <button
                key={language.value}
                type="button"
                onClick={() => handleLanguageSelect(language.value)}
                className={`p-2 rounded-lg border transition-all duration-200 flex flex-col items-center justify-center gap-0.5 ${
                  form.language === language.value
                    ? 'border-violet-500 bg-violet-500/20 text-white'
                    : 'border-gray-700 hover:border-violet-500/50 bg-[#2a2d35] text-gray-300 hover:text-white'
                }`}
              >
                <span className="text-2xl">{language.emoji}</span>
                <span className="text-xs font-medium whitespace-nowrap">{language.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-3">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            3. Какво настроение да има песента?*
          </label>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
            {moodOptions.map((mood) => (
              <button
                key={mood.value}
                type="button"
                onClick={() => handleMoodSelect(mood.value)}
                className={`p-2 rounded-lg border transition-all duration-200 flex flex-col items-center justify-center gap-0.5 ${
                  form.mood === mood.value
                    ? 'border-violet-500 bg-violet-500/20 text-white'
                    : 'border-gray-700 hover:border-violet-500/50 bg-[#2a2d35] text-gray-300 hover:text-white'
                }`}
              >
                <span className="text-base">{mood.emoji}</span>
                <span className="text-xs font-medium whitespace-nowrap">{mood.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-3">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            4. Какъв вокален глас предпочитате?*
          </label>
          <div className="grid grid-cols-3 gap-2">
            {voiceOptions.map((voice) => (
              <button
                key={voice.value}
                type="button"
                onClick={() => handleVoiceSelect(voice.value)}
                className={`p-2 rounded-lg border transition-all duration-200 flex flex-col items-center justify-center gap-0.5 ${
                  form.voice === voice.value
                    ? 'border-violet-500 bg-violet-500/20 text-white'
                    : 'border-gray-700 hover:border-violet-500/50 bg-[#2a2d35] text-gray-300 hover:text-white'
                }`}
              >
                <span className="text-base">{voice.emoji}</span>
                <span className="text-xs font-medium whitespace-nowrap">{voice.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            5. За кого е песента?*
          </label>
          <input
            type="text"
            name="recipientName"
            value={form.recipientName}
            onChange={handleChange}
            placeholder="Напишете името на човека"
            className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

          <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            6. От кого е песента?*
          </label>
          <input
            type="text"
            name="senderName"
            value={form.senderName}
            onChange={handleChange}
            placeholder="Напишете вашето име"
            className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>
        
        <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            7. Песента за специален повод ли е?*
          </label>
          <input
            type="text"
            name="occasion"
            value={form.occasion}
            onChange={handleChange}
            placeholder="Например: рожден ден, годишнина, сватба и т.н."
            className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            8. Какво прави този човек специален?*
          </label>
          <textarea
            name="description"
            value={form.description}
            onChange={handleChange}
            placeholder="Разкажете повече за неговата личност, хобита, любими неща или запомнящи се моменти и истории с него."
            className="w-full h-32 bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 resize-none focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            9. Имате ли конкретна фраза, която искате да включим в песента?
          </label>
          <input
            type="text"
            name="specificPhrase"
            value={form.specificPhrase}
            onChange={handleChange}
            placeholder="Напишете я точно така, както искате да звучи"
            className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

        <div className="space-y-2">
          <label className="flex items-center gap-2 text-left text-sm text-white">
            10. Напишете подробна история или личен разказ.
            <button
              type="button"
              className="text-gray-400 hover:text-white transition-colors relative"
              onMouseEnter={() => setShowHelpText(true)}
              onMouseLeave={() => setShowHelpText(false)}
            >
              <HelpCircle className="w-4 h-4" />
              {showHelpText && (
                <div className="absolute left-6 top-1/2 -translate-y-1/2 w-96 p-4 bg-[#2a2d35] rounded-lg shadow-lg text-sm text-left z-10">
                  <p className="text-gray-300">
                    Разкажете ни най-подробно каква е вашата история. Можете да споделите емоциите и моменти, които искате да бъдат отразени в текста на песента:
                  </p>
                  <p className="mt-2 text-gray-400 italic">
                    Пример: „Когато се запознахме, не очаквахме, че ще бъдем заедно завинаги. Първата ни целувка беше на плажа под звездите. През 2020г заживяхме заедно. Имаме куче на име Роки. Започнахме да пътуваме заедно, откривайки нови места и създавайки спомени."
                  </p>
                </div>
              )}
            </button>
          </label>
          <textarea
            name="additionalInfo"
            value={form.additionalInfo}
            onChange={handleChange}
            placeholder="Опишете вашата история с подробни детайли"
            className="w-full h-32 bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 resize-none focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-violet-500 hover:bg-violet-600 text-white py-3 sm:py-4 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center gap-2 text-sm sm:text-base"
        >
          <span className="text-lg">✨</span>
          Поръчай песен
        </button>

        {submitStatus.type === 'error' && (
          <div className="text-red-400 text-sm mt-2">
            {submitStatus.message}
          </div>
        )}
      </form>

      <OrderCompletionModal
        isOpen={showCompletionModal}
        onClose={() => setShowCompletionModal(false)}
        onComplete={handleOrderComplete}
        formData={form}
      />
    </div>
  );
}