// Facebook Pixel utility functions
const FB_PIXEL_ID = '409300361362867';

declare global {
  interface Window {
    fbq: any;
  }
}

// Initialize Facebook Pixel
export const initFacebookPixel = () => {
  // Create and inject the base pixel code
  const script = document.createElement('script');
  script.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '409300361362867');
    fbq('track', 'PageView');
  `;
  document.head.appendChild(script);

  // Add noscript pixel
  const noscript = document.createElement('noscript');
  const img = document.createElement('img');
  img.height = 1;
  img.width = 1;
  img.style.display = 'none';
  img.src = `https://www.facebook.com/tr?id=409300361362867&ev=PageView&noscript=1`;
  noscript.appendChild(img);
  document.head.appendChild(noscript);
};

// Track purchase event
export const trackPurchase = (orderType: 'normal' | 'express') => {
  if (!window.fbq) {
    console.warn('Facebook Pixel is not initialized');
    return;
  }

  const value = orderType === 'express' ? 153.00 : 93.00;
  
  window.fbq('track', 'Purchase', {
    value: value,
    currency: 'BGN',
    content_type: 'song_order',
    content_name: `${orderType}_order`,
  });
};