import React, { useState } from 'react';
import { auth } from '../lib/firebase';
import { 
  updateProfile, 
  updatePassword, 
  EmailAuthProvider, 
  reauthenticateWithCredential 
} from 'firebase/auth';

export default function ProfileSettings() {
  const [displayName, setDisplayName] = useState(auth.currentUser?.displayName || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleUpdateName = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!auth.currentUser) return;

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await updateProfile(auth.currentUser, {
        displayName: displayName
      });
      setSuccess('Името беше успешно обновено!');
    } catch (err) {
      setError('Възникна грешка при обновяването на името.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!auth.currentUser || !auth.currentUser.email) return;

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Validate new password match
      if (newPassword !== confirmNewPassword) {
        throw new Error('Новите пароли не съвпадат.');
      }

      // Validate new password length
      if (newPassword.length < 6) {
        throw new Error('Новата парола трябва да е поне 6 символа.');
      }

      // Create credentials with current password
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );

      // Reauthenticate user
      await reauthenticateWithCredential(auth.currentUser, credential);

      // Update password
      await updatePassword(auth.currentUser, newPassword);

      // Clear form
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      
      setSuccess('Паролата беше успешно обновена!');
    } catch (err: any) {
      let errorMessage = 'Възникна грешка при обновяването на паролата.';
      
      if (err.message) {
        errorMessage = err.message;
      } else if (err.code === 'auth/wrong-password') {
        errorMessage = 'Текущата парола е грешна.';
      }
      
      setError(errorMessage);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#1a1d25] rounded-xl p-6">
      <h2 className="text-xl font-semibold mb-6">Настройки на профила</h2>

      {error && (
        <div className="mb-4 p-3 bg-red-500/20 text-red-400 rounded-lg">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-3 bg-green-500/20 text-green-400 rounded-lg">
          {success}
        </div>
      )}

      <div className="space-y-8">
        {/* Update Name Form */}
        <form onSubmit={handleUpdateName} className="space-y-4">
          <h3 className="text-lg font-medium">Промяна на име</h3>
          <div>
            <label className="block text-sm font-medium mb-1">
              Име
            </label>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              loading 
                ? 'bg-violet-500/50 cursor-not-allowed' 
                : 'bg-violet-500 hover:bg-violet-600'
            }`}
          >
            {loading ? 'Обновяване...' : 'Обнови име'}
          </button>
        </form>

        {/* Update Password Form */}
        <form onSubmit={handleUpdatePassword} className="space-y-4">
          <h3 className="text-lg font-medium">Промяна на парола</h3>
          <div>
            <label className="block text-sm font-medium mb-1">
              Текуща парола
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              Нова парола
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              Повтори нова парола
            </label>
            <input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="w-full bg-[#2a2d35] rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-violet-500"
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 rounded-lg font-medium transition-colors ${
              loading 
                ? 'bg-violet-500/50 cursor-not-allowed' 
                : 'bg-violet-500 hover:bg-violet-600'
            }`}
          >
            {loading ? 'Обновяване...' : 'Обнови парола'}
          </button>
        </form>
      </div>
    </div>
  );
}