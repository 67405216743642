import React from 'react';
import Footer from './Footer';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-[#0f1117] flex flex-col">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <h1 className="text-3xl font-bold mb-8">Политика за поверителност на Melostory</h1>
          
          <div className="prose prose-invert max-w-none">
            <p className="text-gray-300 mb-8">
              Настоящата Политика за поверителност обяснява как Melostory събира, използва и защитава Вашите лични данни.
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Събиране на лични данни</h2>
              <div className="space-y-2 text-gray-300">
                <p>Melostory събира следната информация:</p>
                <ul className="list-disc pl-6">
                  <li>Име и имейл адрес – предоставени от потребителя при направата на поръчка.</li>
                </ul>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">2. Цели на обработването на личните данни</h2>
              <div className="space-y-2 text-gray-300">
                <p>Събраните данни се използват за:</p>
                <ul className="list-disc pl-6">
                  <li>Изпълнение на поръчки и предоставяне на персонализиран продукт.</li>
                  <li>Комуникация с потребителите относно тяхната поръчка.</li>
                </ul>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">3. Споделяне на данни с трети страни</h2>
              <div className="space-y-2 text-gray-300">
                <p>Melostory използва следните услуги на трети страни:</p>
                <ul className="list-disc pl-6">
                  <li>Google Analytics – за анализ на трафика и подобряване на уебсайта.</li>
                  <li>Firebase – за управление на базата данни и автентикация.</li>
                  <li>Facebook Pixel – за оптимизиране на маркетинг кампании.</li>
                </ul>
                <p>Данните могат да бъдат споделени с тези трети страни в съответствие с техните политики за поверителност.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">4. Сигурност на данните</h2>
              <div className="space-y-2 text-gray-300">
                <p>Melostory предприема подходящи технически и организационни мерки за защита на личните данни от неоторизиран достъп, загуба или унищожаване.</p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">5. Права на потребителите</h2>
              <div className="space-y-2 text-gray-300">
                <p>Потребителите имат право:</p>
                <ul className="list-disc pl-6">
                  <li>Да поискат достъп до своите лични данни.</li>
                  <li>Да изискат коригиране или изтриване на данни.</li>
                  <li>Да оттеглят своето съгласие за обработка на данни.</li>
                </ul>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. Контакти</h2>
              <div className="space-y-2 text-gray-300">
                <p>При въпроси относно тази Политика за поверителност, можете да се свържете с нас на: <a href="mailto:office@melostory.com" className="text-violet-400 hover:text-violet-300">office@melostory.com</a></p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. Промени в Политиката за поверителност</h2>
              <div className="space-y-2 text-gray-300">
                <p>Melostory си запазва правото да променя тази Политика за поверителност по всяко време. Промените ще бъдат публикувани на уебсайта.</p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}